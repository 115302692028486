import React, {useState, useEffect} from 'react';
import DivisionThumbAccesos from './DivisionThumb/DivisionThumbAccesos'
import DivisionThumb from './DivisionThumb/DivisionThumb'
import DivisionContainerInternas from '../DivisionContainer/DivisionContainerInternas'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'

import './Divisiones.scss';

const Telecomunicaciones = () => {

    const [loading, setLoading] = useState(true);

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return    <section className='container-fluid p-0 pb-0 display-block'>
                
                <div className='division__container animated fast fadeIn'>
                   
                    <img 
                        src="../images/headers/headers-telecomunicaciones.jpg" 
                        alt="Reparacion de Tanques." 
                        loading='lazy'
                        className='division__container--img'
                    />

                    <div className='division__container--textos division__container--imgLg' >
                        <h3 className='division__container--header'>DIVISION</h3>
                        <h1 className='division__container--title'>Telecomunicaciones</h1>
                        <p className='division__container--text' style={{textAlign: 'justify' , lineHeight: '1.4rem'}}>

                        Brindamos el servicio de telecomunicaciones, donde la experiencia y la excelencia en el 
                        servicio moderno nos involucran en el mercado. Poseemos equipamientos y herramientas 
                        tecnológicas que nos permiten soluciones precisas y certeras en diseño, ingeniería, 
                        ejecución de obra y mantenimiento en los sectores de telefónica fija, móvil, 
                        comunicación de voz, datos y video, redes informáticas e internet. 

                        </p>
                    </div>

                </div>

                <div className='container container__divisiones'>
                    
                    <div className='text-center division__bajada--down' >
                        <i className="fas fa-angle-down fa-3x"></i>
                    </div>
                    
                    <div className='row justify-content-center proyecto__containers'>

                        <DivisionThumb 
                            imgUrl ={'./images/divisiones/telecomunicaciones/fibra-optica.jpg'}
                            titulo ={'Fibra Optica'}
                            linkUrl = {'/telecomunicaciones/fibra-optica/'}
                        />
                        <DivisionThumb 
                            imgUrl ={'./images/divisiones/telecomunicaciones/dts-y-das.jpg'}
                            titulo ={'DTS y DAS'}
                            linkUrl = {'/telecomunicaciones/dts-das/'}
                        /> 

                        <DivisionThumb 
                            imgUrl ={'./images/divisiones/telecomunicaciones/fttx.jpg'}
                            titulo ={'FTTx'}
                            linkUrl = {'/telecomunicaciones/fttx/'}
                        />

                        <DivisionThumb 
                            imgUrl ={'./images/divisiones/telecomunicaciones/networking.jpg'}
                            titulo ={'Networking'}
                            linkUrl = {'/telecomunicaciones/networking/'}
                        />

                        <DivisionThumb 
                            imgUrl ={'./images/divisiones/telecomunicaciones/videovigilancia-domotica.jpg'}
                            titulo ={'Video Vigilancia'}
                            linkUrl = {'/telecomunicaciones/video-vigilancia/'}
                        />
                        <DivisionThumb 
                            imgUrl ={'./images/divisiones/telecomunicaciones/domotica.jpg'}
                            titulo ={'Automatización y Domótica'}
                            linkUrl = {'/telecomunicaciones/automtizacion-y-domotica/'}
                        />

                    </div>

                    <h3 className='division__bajada--tit text-center'>
                        Otras divisiones
                    </h3>
                    <div>
                        <DivisionContainerInternas divSelect={'telecomunicaciones'} />
                    </div>

                </div>

                <FooterFormulario className='mt-5' />
            </section>

};

export default Telecomunicaciones;
