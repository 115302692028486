import React, {useRef, useEffect, useCallback} from 'react';
import {ReactComponent as FlechaRight} from '../../img/arrow_right.svg'
import {ReactComponent as FlechaLeft} from '../../img/arrow_left.svg'

import styled from 'styled-components'



const Slideshow = ( {
        children, 
        controles = true, 
        autoplay = false, 
        velocidad = '500', 
        intervalo='5000'
    } ) => {

    const slideshow = useRef(null);
    const intervaloSlideShow = useRef(null);

    const siguiente = useCallback ( () => {

        if(slideshow.current.children.length > 0){

            // obtenemos primer elemento
            const primerElemento = slideshow.current.children[0];
            // definimos transicion
            slideshow.current.style.transition = `${velocidad}ms ease-out all`;
            const tamañoSlide = slideshow.current.children[0].offsetWidth;
            // movemos el slide
            slideshow.current.style.transform = `translateX(-${tamañoSlide}px)`;

            const transicion = () => {

                // reiniciamos la posicion del slideshow
                slideshow.current.style.transition = 'none';
                slideshow.current.style.transform = `translateX(0)`;
                // mandamos el primer elemento al final
                slideshow.current.appendChild(primerElemento);
                // removemos el eventListener.
                slideshow.current.removeEventListener('transitionend', transicion)

            }

            // Eventlistener para cuando temina la animacion
            slideshow.current.addEventListener('transitionend', transicion)
            
        }
    }, [velocidad]);
    
    
    const anterior = () => {  
        if(slideshow.current.children.length > 0 ){

            const index = slideshow.current.children.length - 1;
            const ultimoElemento = slideshow.current.children[index];

            // insertamos el ultimo elemento antes del primero
            slideshow.current.insertBefore(ultimoElemento, slideshow.current.firstChild);

            slideshow.current.style.transition = 'none';
            const tamañoSlide = slideshow.current.children[0].offsetWidth;
            slideshow.current.style.transform = `translateX(-${tamañoSlide}px)`;

            setTimeout (()=>{
                slideshow.current.style.transition = `${velocidad}ms ease-out all`;
                slideshow.current.style.transform = `translateX(0)`;
            }, intervalo)

        }
    }

    useEffect( () => {
        if(autoplay) {
                
                intervaloSlideShow.current = setInterval(()=> {
                    siguiente();
                }, intervalo)

                //eliminamos los intervalos
                slideshow.current.addEventListener('mouseenter', () => {
                    clearInterval(intervaloSlideShow.current)
                })

                //Volvemos a poner el intervalo de Autoplay
                slideshow.current.addEventListener('mouseleave', () => {
                    intervaloSlideShow.current = setInterval(()=>{
                        siguiente();
                    }, intervalo)
                });
            }
        }, [autoplay, intervalo, siguiente]);


    return (
        <ContenedorPrincial>
            <ContenedorSlideshow ref={slideshow}>
                {children}
            </ContenedorSlideshow>
            {controles && <Controles>
                <Boton onClick={anterior}><FlechaLeft /></Boton>
                <Boton derecho  onClick={siguiente}><FlechaRight /></Boton>
            </Controles>}
        </ContenedorPrincial>
    );
};



const ContenedorPrincial = styled.div`
    position: relative;
`;

const ContenedorSlideshow = styled.div`
 display: flex;
 flex-wrap: nowrap;
`;

const Slide = styled.div`

    min-width: 100%;
    overflow: hidden;
    transition: .3s ease all;
    /* z-index: 10; */
    max-height: 500px;
    position: relative;

    img {
        width: 100%;
        vertical-align: top;
    }

`;


const TextoSlide = styled.div`

    background: ${props => props.colorBack ? props.colorBack : 'rgba(0,0,0,.4)'};
    color: ${props => props.colorText ? props.colorText : '#fff'};

    width: 100%;
    padding: 10px 60px;
    text-align: center;

    position: absolute;
    bottom: 50%;

    @media screen and (max-width: 700px){
        position: relative;
    }
`;

const Controles = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    
`

const Boton = styled.button`

    background-color: rgba(0,0,0,0);
    border: none;
    pointer-events: all;
    outline: none;
    width: 70px;
    height: 100%;
    text-align: center;
    position: absolute;
    transition: all .3s ease;
    padding: 2px;

    &:hover {
        path{
            fill: #fff;
            transition: all .5s ease;
        }
    }

    ${props => props.derecho ? 'right : 0' :  'left: 0'}

`

export  {Slideshow, Slide, TextoSlide};
