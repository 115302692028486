import { type } from '@testing-library/user-event/dist/type';
import React, {useState} from 'react'
import { useForm } from 'react-hook-form'

import Button from '../../Commons/Button/Button'
import './FooterFormulario.scss'
import emailjs from 'emailjs-com';


const FooterFormulario = ( props ) => {

    const [formSent, setFormSent] = useState(false)

    const {
        register, 
        resetField,
        handleSubmit, 
        formState: { errors }} = useForm({
        defaultValues:{
            userName: '',
            userTelephone: '',
            userEmail: '',
            userMessage: '',
            my_file: ''
        }
    });

    function sendEmail(data, e){

        e.preventDefault();
        setFormSent(true)

        if(props.formOrigin === 'trabajaConNosotros' ) {

            // TRABAJA CON NOSOTROS
            emailjs.sendForm('service_belsp4z', 'template_tdb3zve', e.target, 'u46ocKQ3h_ngAAYuz').then(

                res => {
                    
                    resetField('userName'); resetField('userTelephone'); resetField('userEmail'); resetField('userMessage');
                    console.log('TRABAJA CON NOSOTROS, ', res);

                    setTimeout( ()=>{
                        setFormSent(false);
                    }, 3000)

                    e.target.reset();

                }
            )

        }else{

            //CONTACTO
            emailjs.sendForm('service_belsp4z', 'template_rxmza1g', e.target, 'u46ocKQ3h_ngAAYuz').then(

                res => {
                    
                    resetField('userName'); resetField('userTelephone'); resetField('userEmail'); resetField('userMessage');
                    console.log('CONTACTO FOOTER, ', res);

                    setTimeout( ()=>{
                        setFormSent(false);
                    }, 3000)

                    e.target.reset();
                
                }
            )
            
        }


    }

    return (

        <section className='container-fluid p-0 formContainer'>
           
            <div className='container'>
                
                <form className='row d-flex justify-content-center' onSubmit={handleSubmit(sendEmail)} >
                    
                    <div className='col-md-5 formInputsBlock'>
                        
                        <input 
                            { ...register( 'userName', { 
                                required: 'El nombre solo puede contener letras y espacios.', 
                                pattern: { 
                                    value: /[A-Za-z]{3}/, 
                                    message: 'El nombre solo acepta letras y 3 como minimo.'
                                }
                            })}

                            placeholder='Nombre/Empresa *' className='formInput__box'  />
                        <p className='formInput__error'> {errors.userName?.message}</p>
                        
                        <input 
                            type = 'number'
                            { ...register('userTelephone', { 
                            required: 'El télefono solo puede contener numeros, guiones y parentesis y no menos de 8 numeros.'
                            , minLength: { value: 8, message: 'El nuemero de ser de mīnimo de 8 caracteres'} })} 
                            placeholder='Telefono *' className='formInput__box'  />
                        <p className='formInput__error'> {errors.userTelephone?.message}</p>
                        
                        <input 
                            
                            {...register('userEmail', { 
                                required: 'El solo puede contener letras, numeros, puntos y guiones bajos', 
                                pattern: { 
                                    value: /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]/ ,
                                    error: 'error de mail'
                                }   
                            })} 
                            
                            placeholder='Email *' className='formInput__box'  />
                        <p className='formInput__error'> {errors.userEmail?.message}</p>
                    </div>
                    
                    <div className='col-md-5'>
                        <textarea  { ...register('userMessage', { 
                                required: 'El mensaje debe tener mas de 30 caracteres', 
                                minLength: {
                                    value: 30, 
                                    message: 'El mensaje debe tener mas de 30 caracteres.'
                                } 
                            })} 
                            placeholder='Deje su mensaje. *' 
                            className='formInput__box formInput__textarea' >
                        </textarea>
                        <p className='formInput__error'> {errors.userMessage?.message}</p>
                    </div>

                    { props.formOrigin === 'trabajaConNosotros'
                        ? <div className='col-md-10 buttonContainerUpload'>
                            <label className='buttonContainerUpload--label' style={{cursor: 'pointer'}}>Subir CV en formato PDF </label>
                            {/* <input type='file' name='useFile' id='fileupload' {...register ('useFile', {
                                required: 'El mensaje debe tener mas de 30 caracteres', 
                                max: {
                                    size: '3mb',
                                    message: 'El archivo debe pesar menos de 3mb.'
                                }
                            })} /> */}

                            <input type="file" name="my_file" /> 
                            {/* <Button toDo='Seleccionar...' type='none'/> */}

                        </div> : ''
                    }

                    <div className='col-md-10 text-center pt-5 pb-0 mb-3'>
                        {formSent &&  <p className='formInput__sent'>Formulario enviado. Proximamente nos comunicaremos.<br /> Muchas Gracias</p>}
                        <Button toDo='Enviar' type='submit' />
                    </div>

                </form>
                
            </div>

        </section>
    )
}

export default FooterFormulario
