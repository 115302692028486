import React, {useState, useEffect} from 'react'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import DivisionBanner from '../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'

import '../../Styles/main.scss'
import '../Divisiones/DivisionesDetalles.scss'
import OtrasObras from '../Commons/ObrasRealizadas/OtrasObras';

const RadiadorGuascor = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Radiador Guascor .</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>
{/* 
              <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer> */}

            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-aislaciones-termicas.jpg" 
                    alt="Obras, andamios." 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-obras' >
                    <h3 className='divisionItem__container--header'>OBRAS</h3>
                    <h1 className='divisionItem__container--title'>Radiador Guascor </h1>
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/obras/'}>
                        <small> 
                        <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                        Volver a Obras
                        </small>
                    </Link>

                    <div className='row'>

                        <div className='col-md-6'>     
                            <img 
                                src="../images/obras/radiador-guascor/radiador-guascor-00.jpg" 
                                alt="Aislación de piping en PIUS."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            /> 
                        </div>

                        <div className='col-md-6 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Radiador</h1>
                            <h3 className='divisionItem__detail--subtitle' >
                            Guascor FG 240
                            </h3>
                            <p  className='divisionItem__detail--text' > 
                                Se realiza el mantenimiento y reparación de enfriadores de aceite en nuestra base operativa.<br /><br />
                                Nuestro personal operativo se encarga de desarmar el cilindro, sondear cada tubo y reparar las pérdidas que surjan luego de las pruebas hidráulicas correspondientes.<br /><br />
                                Finalizando con la limpieza, armado de piezas y pintura sintetica. 
                            </p>
                        </div>

                    </div>

                    <div className='gallery__cont'>

                        <div className='gallery__cont--column25'>
                            <img    onClick={ () => handleShow('../images/obras/radiador-guascor/radiador-guascor-01.jpg', 'Radiador Guascor .') } src='../images/obras/radiador-guascor/radiador-guascor-01.jpg' alt='Radiador Guascor ' loading='lazy'/>
                        </div>

                        <div className='gallery__cont--column25'>
                            <img    onClick={ () => handleShow('../images/obras/radiador-guascor/radiador-guascor-05.jpg', 'Radiador Guascor .') } src='../images/obras/radiador-guascor/radiador-guascor-05.jpg' alt='Radiador Guascor ' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/radiador-guascor/radiador-guascor-02.jpg', 'Radiador Guascor .') } src='../images/obras/radiador-guascor/radiador-guascor-02.jpg' alt='Radiador Guascor ' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column25'>
                            <img    onClick={ () => handleShow('../images/obras/radiador-guascor/radiador-guascor-03.jpg', 'Radiador Guascor .') } src='../images/obras/radiador-guascor/radiador-guascor-03.jpg' alt='Radiador Guascor ' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column25'>
                            <img    onClick={ () => handleShow('../images/obras/radiador-guascor/radiador-guascor-04.jpg', 'Radiador Guascor .') } src='../images/obras/radiador-guascor/radiador-guascor-04.jpg' alt='Radiador Guascor ' loading='lazy' />
                        </div>
                        <div className='gallery__cont--column25'>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='row divisionItem__proyectosDestacados p-0'> 
                    <OtrasObras  itemToShowParam={3} itemsToShowParam={5} />
                </div>
            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default RadiadorGuascor