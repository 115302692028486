import React, {useState, useEffect} from 'react';
import DivisionThumb from './DivisionThumb/DivisionThumb'
import DivisionContainerInternas from '../DivisionContainer/DivisionContainerInternas'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom';


import './Divisiones.scss';

const AislacionesTermicas = () => {

    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };


    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return    <section className='container-fluid p-0 pb-0 display-block'>

                <Modal
                    show={show}
                    size='lg'
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    >
                    <Modal.Header closeButton>
                        <Modal.Title>Cintas calefactoras.</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
                    </Modal.Body>

                    <Modal.Footer>
                        <small >{txtDes}</small>
                    </Modal.Footer>
                </Modal>

                <div className='divisionItem__container animated fast fadeIn'>
                    <img src="../images/headers/headers-aislaciones-termicas.jpg" 
                        alt="Reparacion de Tanques." 
                        loading='lazy'
                        className='divisionItem__container--img'    
                    />
                    <div className='divisionItem__container--textos' >
                        <h3 className='divisionItem__container--header'>DIVISION</h3>
                        <h1 className='divisionItem__container--title'>AISLACIONES TERMICAS</h1>
                        
                    </div>
                </div>

                <div className='container-fluid divisionItem__detail'>
                    <div className='container animated fast fadeIn'>

                    <div className='row justify-content-center proyecto__containers'>
                            <DivisionThumb 
                                imgUrl ={'./images/divisiones/aislaciones-termicas/cinta-calefactora/cinta-calefactora-00.jpg'}
                                titulo ={'Cinta calefactora'}
                                linkUrl = {'/aislaciones-termicas/cinta-calefactora/'}
                                altImg={'Cinta calefactora'}
                            />
                            <DivisionThumb 
                                imgUrl ={'./images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-00.jpg'}
                                titulo ={'Recubrimiento chapa'}
                                linkUrl = {'/aislaciones-termicas/recubrimiento-chapa/'}
                                altImg={'Recubrimiento de chapa'}
                            />
                            
                    </div>

                       
                    </div>
                </div>
                <div className='container container__divisiones'>
                    
                    <div className='text-center division__bajada--down' >
                        <i className="fas fa-angle-down fa-3x"></i>
                    </div>
                    
                    {/* <h3 className='division__bajada--tit'>
                        Tareas Destacadas
                    </h3> */}
                
                    <h3 className='division__bajada--tit text-center'>
                        Otras divisiones
                    </h3>
                    <div>
                        <DivisionContainerInternas divSelect={'aislaciones-termicas'} />
                    </div>

                </div>

                <FooterFormulario className='mt-5' />
            </section>

};

export default AislacionesTermicas;
