import React, {useState, useEffect} from 'react'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import '../Divisiones/DivisionesDetalles.scss'
import DivisionBanner from '../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'
import ObraThumb from '../Commons/ObraThumb/ObraThumb';
import OtrasObras from '../Commons/ObrasRealizadas/OtrasObras';


const LargosVanos = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Fibra Optica para Largos Vanos</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>

              {/* <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer> */}

            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-fo-largos-vanos.jpg" 
                    alt="Obras, andamios." 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-obras' >
                    <h3 className='divisionItem__container--header'>OBRAS</h3>
                    <h1 className='divisionItem__container--title'>TELECOMUNICACIONES</h1>
                </div>

            </div>

            <div className='container-fluid obrasItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/obras/'}>
                        <small> 
                        <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                        Volver a Obras
                        </small>
                    </Link>

                    <div className='row'>

                        <div className='col-md-5'>     
                            <img 
                                src="../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-00.jpg" 
                                alt="Fibra Optica para Largos Vanos de Agua."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            /> 
                        </div>

                        <div className='col-md-7 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >FO para Largos Vanos.</h1>
                            
                            <p  className='divisionItem__detail--text pt-2' > 

                            Green Energy, Parque Eólico Pampa de Castillo, 
                            implementamos un tendido de 26km de FO de largos vanos (280 metros en promedio) 
                            en línea de Alta tención 132 KV a 11mts de altura en promedio.<br />
                            Esta obra se realizó en un lapso de 4 meses.<br/>

                            <br />
                            </p>

                        </div>

                    </div>
                    
                    <div className='row obras__box'>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb  
                                ObraNum  = {'26'}
                                ObraVal  = {''}
                                ObraItem = {'KM'}
                                ObraDet  = {'de Tendido'}
                            />
                        </div>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb  
                                ObraNum  = {'270'}
                                ObraVal ={''}
                                ObraItem = {'mts'}
                                ObraDet  = {'Altura mínima de Vanos'}
                            />
                        </div>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb  
                                ObraNum  = {'48'}
                                ObraVal ={''}
                                ObraItem = {'KM'}
                                ObraDet  = {'Cableado'}
                            />
                        </div>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb  
                                ObraNum  = {'8'}
                                ObraVal ={''}
                                ObraItem = {'Empalmes'}
                                ObraDet  = {'OPGW'}
                            />
                        </div>
                    </div>


                    <div className='gallery__cont'>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-01.jpg', 'Fibra Optica para Largos Vanos') } src='../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-01.jpg' alt='Fibra Optica para Largos Vanos' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-02.jpg', 'Fibra Optica para Largos Vanos') } src='../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-02.jpg' alt='Fibra Optica para Largos Vanos' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-03.jpg', 'Fibra Optica para Largos Vanos') } src='../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-03.jpg' alt='Fibra Optica para Largos Vanos' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-04.jpg', 'Fibra Optica para Largos Vanos') } src='../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-04.jpg' alt='Fibra Optica para Largos Vanos' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-08.jpg', 'Fibra Optica para Largos Vanos') } src='../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-08.jpg' alt='Fibra Optica para Largos Vanos' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-07.jpg', 'Fibra Optica para Largos Vanos') } src='../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-07.jpg' alt='Fibra Optica para Largos Vanos' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-11.jpg', 'Fibra Optica para Largos Vanos') } src='../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-11.jpg' alt='Fibra Optica para Largos Vanos' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-14.jpg', 'Fibra Optica para Largos Vanos') } src='../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-14.jpg' alt='Fibra Optica para Largos Vanos' loading='lazy'/>
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-06.jpg', 'Fibra Optica para Largos Vanos') } src='../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-06.jpg' alt='Fibra Optica para Largos Vanos' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-09.jpg', 'Fibra Optica para Largos Vanos') } src='../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-09.jpg' alt='Fibra Optica para Largos Vanos' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-10.jpg', 'Fibra Optica para Largos Vanos') } src='../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-10.jpg' alt='Fibra Optica para Largos Vanos' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-12.jpg', 'Fibra Optica para Largos Vanos') } src='../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-12.jpg' alt='Fibra Optica para Largos Vanos' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-13.jpg', 'Fibra Optica para Largos Vanos') } src='../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-13.jpg' alt='Fibra Optica para Largos Vanos' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-05.jpg', 'Fibra Optica para Largos Vanos') } src='../images/obras/telecomunicaciones/fo-para-largos-vanos/fo-para-largos-vanos-05.jpg' alt='Fibra Optica para Largos Vanos' loading='lazy' />
                        </div>

                    </div>
                </div>
            </div>
            <div>
                <div className='row divisionItem__proyectosDestacados p-0'> 
                    <OtrasObras  itemToShowParam={13} itemsToShowParam={5} />
                </div>
            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default LargosVanos