
import '../src/Styles/main.scss';
import {BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom';

import Navbar       from './Components/Commons/Navbar/Navbar';
import Footer       from './Components/Commons/Footer/Footer';
import Home         from './Components/Home/Home';
import QuienesSomos from './Components/QuienesSomos/QuienesSomos';
import Obras        from './Components/Obras/Obras';
import Contacto     from './Components/Contacto/Contacto';
import PoliticaIntegral from './Components/PoliticaIntegral/PoliticaIntegral';


import ReparacionDeTanques  from './Components/Divisiones/ReparacionDeTanques';
import ArenadoPintura from './Components/Divisiones/ReparacionDeTanques/ArenadoPintura';
import TechoReparacionConstruccion from './Components/Divisiones/ReparacionDeTanques/TechoReparacionConstruccion';

import AislacionesTermicas from './Components/Divisiones/AislacionesTermicas';
import ArmadoAndamios from './Components/Divisiones/ArmadoAndamios';
import Radiadores from './Components/Divisiones/Radiadores';
import ObrasCiviles from './Components/Divisiones/ObrasCiviles';
import PisoReparacionConstruccion from './Components/Divisiones/ReparacionDeTanques/PisoReparacionConstruccion';
import Pead from './Components/Divisiones/ReparacionDeTanques/Pead';
import StillPipe from './Components/Divisiones/ReparacionDeTanques/StillPipe';
import CaneriasAccesorios from './Components/Divisiones/ReparacionDeTanques/CaneriasAccesorios';
import CintaCalefactora from './Components/Divisiones/AislacionesTermicas/CintaCalefacotra';
import RecubrimientoChapa from './Components/Divisiones/AislacionesTermicas/RecubrimientoChapa';
import PisosTanques from './Components/Divisiones/ObrasCiviles/PisosTanques';
import Anillo from './Components/Divisiones/ObrasCiviles/Anillo';
import Andamio20M from './Components/Obras/Andamio20M';
import ObraCivilAntares from './Components/Obras/ObraCivilAntares';
import Tanque10000 from './Components/Obras/Tanque10000';
import Tanque20000 from './Components/Obras/Tanque20000';
import Tanque50000 from './Components/Obras/Tanque50000';
import Tanque5000 from './Components/Obras/Tanque5000';
import TrabajaConNosotros from './Components/Contacto/TrabajaConNosotros';

import Telecomunicaciones from './Components/Divisiones/Telecomunicaciones';
import FibraOptica from './Components/Divisiones/Telecomunicaciones/FibraOptica';
import DtsDas from './Components/Divisiones/Telecomunicaciones/DtsDas';
import FTTx from './Components/Divisiones/Telecomunicaciones/FTTx';
import Networking from './Components/Divisiones/Telecomunicaciones/Networking';
import VideoVigilancia from './Components/Divisiones/Telecomunicaciones/VideoVigilancia';
import AutomatizacionDomotica from './Components/Divisiones/Telecomunicaciones/AutomatizacionDomotica';

import EnlacesUrbanosPuntoAPunto from './Components/Obras/EnlacesUrbanosPuntoAPunto';
import PicoDeAgua from './Components/Obras/PicoDeAgua';
import LagunaDeLosPadres from './Components/Obras/LagunaDeLosPadres';
import EnlacesRadaTilly from './Components/Obras/EnlacesRadaTilly';
import EnlacesPuntoAPuntoLargoVanos from './Components/Obras/EnlacesPuntoAPuntoLargoVanos';
import BancoGaliciaPlaza from './Components/Obras/BancoGaliciaPlaza';
import LargosVanos from './Components/Obras/LargosVanos';
import BancalesIndustriales from './Components/Divisiones/ObrasCiviles/BancalesIndustriales';
import BancalesParaCanerias from './Components/Divisiones/ObrasCiviles/BancalesParaCanerias';
import BancalesParaCaneriasPurgas from './Components/Divisiones/ObrasCiviles/BancalesParaCaneriasPurgas';
import Veredas from './Components/Divisiones/ObrasCiviles/Veredas';
import AislacionDeLH03 from './Components/Obras/AislacionDeLH03';
import AislacionPiu from './Components/Obras/AislacionPiu';
import Spool from './Components/Obras/Spool';
import EnfriadorDeAceite from './Components/Obras/EnfriadorDeAceite';
import RadiadorGuascor from './Components/Obras/RadiadorGuascor';
import CapsaCapex from './Components/Obras/CapsaCapex';

function App() {
  return (
    <div className="App">
      <HashRouter>
      {/* <BrowserRouter> */}
        <Navbar />
        <Routes>

          <Route exact path='/'                                                     element={ <Home /> } />
          <Route exact path='/quienes-somos/'                                       element={ <QuienesSomos /> } />

          <Route exact path='/reparacion-tanques/'                                  element={ <ReparacionDeTanques /> } />
          <Route exact path='/reparacion-tanques/arenado-pintura/'                  element={ <ArenadoPintura /> } />
          <Route exact path='/reparacion-tanques/techo-reparacion-y-construccion/'  element={ <TechoReparacionConstruccion /> } />
          <Route exact path='/reparacion-tanques/piso-reparacion-y-construccion/'   element={ <PisoReparacionConstruccion /> } />
          <Route exact path='/reparacion-tanques/pead/'                             element={ <Pead /> } />
          <Route exact path='/reparacion-tanques/still-pipe/'                       element={ <StillPipe /> } />
          <Route exact path='/reparacion-tanques/canerias-y-accesorios/'            element={ <CaneriasAccesorios /> } />
          
          <Route exact path='/armado-andamios/'                                     element={ <ArmadoAndamios /> } />

          <Route exact path='/aislaciones-termicas/'                                element={ <AislacionesTermicas /> } />
          <Route exact path='/aislaciones-termicas/cinta-calefactora/'              element={ <CintaCalefactora /> } />
          <Route exact path='/aislaciones-termicas/recubrimiento-chapa/'            element={ <RecubrimientoChapa /> } />

          <Route exact path='/radiadores/'                                          element={ <Radiadores /> } />

          <Route exact path='/telecomunicaciones/'                                  element={ <Telecomunicaciones /> } />
          <Route exact path='/telecomunicaciones/fibra-optica/'                     element={ <FibraOptica /> } />
          <Route exact path='/telecomunicaciones/dts-das/'                          element={ <DtsDas /> } />
          <Route exact path='/telecomunicaciones/fttx/'                             element={ <FTTx /> } />
          <Route exact path='/telecomunicaciones/networking/'                       element={ <Networking /> } />
          <Route exact path='/telecomunicaciones/video-vigilancia/'                 element={ <VideoVigilancia /> } />
          <Route exact path='/telecomunicaciones/automtizacion-y-domotica/'         element={ <AutomatizacionDomotica /> } />
          
          <Route exact path='/obras-civiles-industriales/'                          element={ <ObrasCiviles /> } />
          <Route exact path='/obras-civiles-industriales/pisos-tanques/'            element={ <PisosTanques /> } />
          <Route exact path='/obras-civiles-industriales/anillo/'                   element={ <Anillo /> } />
          <Route exact path='/obras-civiles-industriales/bancales-industriales/'    element={ <BancalesIndustriales /> } />
          <Route exact path='/obras-civiles-industriales/bancales-para-canerias/'   element={ <BancalesParaCanerias /> } />
          <Route exact path='/obras-civiles-industriales/camara-drenaje-de-purgas/' element={ <BancalesParaCaneriasPurgas /> } />
          <Route exact path='/obras-civiles-industriales/veredas/'                  element={ <Veredas /> } />

          <Route exact path='/politica-integral/'                                   element={ <PoliticaIntegral /> } />

          <Route exact path='/obras/'                                               element={ <Obras /> } />
          <Route exact path='/obras/andamio-20m/'                                   element={ <Andamio20M /> } />
          <Route exact path='/obras/obra-civil-antares/'                            element={ <ObraCivilAntares /> } />
          <Route exact path='/obras/tanque-5000m/'                                  element={ <Tanque5000 /> } />
          <Route exact path='/obras/tanque-10000m/'                                 element={ <Tanque10000 /> } />
          <Route exact path='/obras/tanque-20000m/'                                 element={ <Tanque20000 /> } />
          <Route exact path='/obras/tanque-50000m/'                                 element={ <Tanque50000 /> } />
          <Route exact path='/obras/enlaces-urbanos-punto-a-punto/'                 element={ <EnlacesUrbanosPuntoAPunto /> } />
          <Route exact path='/obras/enlaces-en-rada-tilly/'                         element={ <EnlacesRadaTilly /> } />
          <Route exact path='/obras/enlace-punto-a-punto-de-largos-vanos/'          element={ <EnlacesPuntoAPuntoLargoVanos /> } />
          <Route exact path='/obras/coop-de-laguna-de-los-padres/'                  element={ <LagunaDeLosPadres />} />
          <Route exact path='/obras/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/' element={<PicoDeAgua /> } />
          <Route exact path='/obras/banco-galicia-plaza/'                           element={ <BancoGaliciaPlaza /> } />
          <Route exact path='/obras/fo-para-largos-vanos/'                          element={ <LargosVanos /> } />
          <Route exact path='/obras/aislacion-de-lh03/'                             element={ <AislacionDeLH03 /> } />
          <Route exact path='/obras/aislacion-en-piu/'                              element={ <AislacionPiu /> } />
          <Route exact path='/obras/spool/'                                       element={ <Spool /> } />
          <Route exact path='/obras/enfriador-de-aceite/'                           element={ <EnfriadorDeAceite /> } />
          <Route exact path='/obras/radiador-guascor-fg-24/'                        element={ <RadiadorGuascor /> } />
          <Route exact path='/obras/capsa-capex/'                                   element={ <CapsaCapex /> } />


          
          <Route exact path='/contacto/'                                            element={ <Contacto /> } />
          <Route exact path='/trabaja-con-nosotros/'                                element={ <TrabajaConNosotros /> } />

          <Route path="*" element={<h2 className='text-center' style={{ paddingTop: 180, paddingBottom: 180}} >404 - Página no encontrada.</h2>}/>
        
        </Routes>
        <Footer />
      {/* </BrowserRouter> */}
      </HashRouter>
    </div>
  );
}

export default App;
