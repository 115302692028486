import React from 'react'
import { Link } from 'react-router-dom'
import './ObrasRealizadas.scss'

import ObrasRealizadasItem from './ObrasRealizadasItem'
import listObras from '../../../obras.json'

const OtrasObras = ({ itemToShowParam, itemsToShowParam}) => {

    console.log(Object.keys(listObras[0]))
    let itemToShow = itemToShowParam;
    let itemsToShow = itemsToShowParam;
    let itemsToIterar = listObras.length - 1;
    let newListObrasToShow = []; 
    listObras = listObras.sort(() => Math.random() - 0.5)

    function newItemsToShow () {
        for (let i = 0; i < itemsToIterar; i++) {
            console.log(newListObrasToShow.length )
            if(newListObrasToShow.length < itemsToShow)
                {   if(listObras[i].id !== itemToShow) { 
                    newListObrasToShow.push(listObras[i])
                }
            }
        }
    }
    newItemsToShow();

    return (
        <section className='container-fluid obras__container'>
            <div className='container'>
                <h3 className='obras__titles obras__titles--box text-center pb-5 pt-0'>Otras Obras</h3>
                <div className='row d-flex justify-content-center'>
                    {newListObrasToShow.map((item) => { 
                        return <ObrasRealizadasItem 
                            img={item.img}
                            alt={item.alt}
                            link={item.link}
                            text={item.text}
                        />
                    })}
                </div>
            </div>
        </section>
    )
}

export default OtrasObras
