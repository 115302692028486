import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../Button/Button'

const ObrasRealizadasItem = ({ img, alt, link, text}) => {
  return (
    <div className='col-md-2 obra__item'>
    <img src={img} className="obras__realizadas" alt={alt} />
    <Link to={link}>
        <div className="background-hover">
            <p className="obra__item--text">{text}</p>
            <Button toDo='Ver más' type='button'/>
        </div> 
    </Link>
</div>
  )
}

export default ObrasRealizadasItem