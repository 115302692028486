import React, { useState, useEffect } from 'react'
import { Carousel } from 'react-bootstrap'
import './Carousel.scss';
import LoadingCarousel from '../Loading/LoadingCarousel';

const CarouselQuienesSomos = ( props ) => {

    const [loading, setLoading] = useState(true);

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })


    const [index, setIndex] = useState(props.index);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    if(loading) {return <LoadingCarousel />}

    return (
        
        <section>
            <Carousel activeIndex={index} className='carousel__box  animated fast fadeIn' indicators={ false } onSelect={ handleSelect } >
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/inicios-01.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/inicios-02.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/inauguracion-base-nueva.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/inauguracion-base-nueva-placa-v.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/inauguracion-base-nueva-placa-1.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/inauguracion-base-nueva-equipo.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-01.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-02.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block carousel__box--image" src="../../images/quienes-somos/trabajadores-04.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block carousel__box--image" src="../../images/quienes-somos/trabajadores-03.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-06.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-07.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-05.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-08.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-09.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-10.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-11.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-12.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-ci-01.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-ci-03.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-ci-04.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-ci-05.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-equipo-03.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-equipo-05.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-ci-03.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="../../images/quienes-somos/trabajadores-ci-06.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-ci-07.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-equipo-02.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-ci-08.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block w-100  carousel__box--image" src="../../images/quienes-somos/trabajadores-equipo-01.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block carousel__box--image" src="../../images/quienes-somos/trabajadores-equipo-04.jpg" alt="Construcciones Integrales, fotos varias de nuestra historia." loading="lazy" />  </Carousel.Item>
            </Carousel> 
        </section>
    )
}

export default CarouselQuienesSomos
