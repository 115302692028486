import React, {useState, useEffect} from 'react'
import Loading from '../../Commons/Loading/Loading';
import FooterFormulario from '../../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import '../DivisionesDetalles.scss'
import ObrasRealizadasItem from '../../Commons/ObrasRealizadas/ObrasRealizadasItem';
import { Modal } from 'react-bootstrap'


const PisosTanques = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Obra de pisos de tanques.</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>

              <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer>
            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-reparacion-de-tanques.jpg" 
                    alt="Reparacion de Tanques." 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos' >
                    <h3 className='divisionItem__container--header'>DIVISION</h3>
                    <h1 className='divisionItem__container--title'>OBRAS CIVILES INDUSTRIALES</h1>
                    
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/obras-civiles-industriales/'}>
                    <small> 
                        <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                        Volver a Division</small>
                    </Link>

                    <div className='row'>
                        <div className='col-md-6'> 
                            
                            <img 
                                src="../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-01.jpg" 
                                alt="Tanque de 50.000m3. con la obra terminada."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            /> 

                        </div>

                        <div className='col-md-6 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Pisos de tanques</h1>
                            <p  className='divisionItem__detail--text' > 
                                Hormigón de piso perdido: Se realizan pisos perdidos en los tanques de almacenamiento con espesores entre 110 mm a 300 mm dependiendo la magnitud de cada tanque y requerimiento del cliente. Su cálculo y diseño incluye armaduras principales, armaduras en puntos críticos como bajo columnas.<br /><br />Se realizan los controles de calidad correspondientes como asentamiento a pie de obra, ejecución de probetas para obtener la calidad del hormigón, y el curado final según su época del año. El hormigón elaborado es a pedido del cliente, esto significa que hacemos las dosificaciones adecuadas para cada caso.
                            </p>
                            
                        </div>

                    </div>

                    <div className='gallery__cont'>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-01.jpg', 'Obras de pisos de tanques') }  src='../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-01.jpg' alt='Obra de pisos de tanques.' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-03.jpg', 'Obras de pisos de tanques') } src='../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-03.jpg' alt='Obra de pisos de tanques.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-02.jpg', 'Obras de pisos de tanques') }   src='../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-02.jpg' alt='Obra de pisos de tanques.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-04.jpg', 'Obras de pisos de tanques') } src='../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-04.jpg' alt='Obra de pisos de tanques.' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-05.jpg', 'Obras de pisos de tanques') } src='../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-05.jpg' alt='Obra de pisos de tanques.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-06.jpg', 'Obras de pisos de tanques') } src='../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-06.jpg' alt='Obra de pisos de tanques.' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-07.jpg', 'Obras de pisos de tanques') } src='../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-07.jpg' alt='Obra de pisos de tanques.' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-08.jpg', 'Obras de pisos de tanques') } src='../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-08.jpg' alt='Obra de pisos de tanques.' loading='lazy' /> 
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-09.jpg', 'Obras de pisos de tanques') } src='../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-09.jpg' alt='Obra de pisos de tanques.' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-10.jpg', 'Obras de pisos de tanques') } src='../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-10.jpg' alt='Obra de pisos de tanques.' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-11.jpg', 'Obras de pisos de tanques') } src='../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-11.jpg' alt='Obra de pisos de tanques.' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-12.jpg', 'Obras de pisos de tanques') } src='../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-12.jpg' alt='Obra de pisos de tanques.' loading='lazy' /> 
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-13.jpg', 'Obras de pisos de tanques') } src='../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-13.jpg' alt='Obra de pisos de tanques.' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-14.jpg', 'Obras de pisos de tanques') } src='../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-14.jpg' alt='Obra de pisos de tanques.' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-15.jpg', 'Obras de pisos de tanques') } src='../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-15.jpg' alt='Obra de pisos de tanques.' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-16.jpg', 'Obras de pisos de tanques') } src='../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-16.jpg' alt='Obra de pisos de tanques.' loading='lazy' /> 
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-17.jpg', 'Obras de pisos de tanques') } src='../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-17.jpg' alt='Obra de pisos de tanques.' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-18.jpg', 'Obras de pisos de tanques') } src='../images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-18.jpg' alt='Obra de pisos de tanques.' loading='lazy' /> 
                        </div> 
                    
                    </div>
                </div>
            </div>
            
            <div className='container-fluid otros__proyectos'>
                <div className='container'>
                    <h3 className='obras__titles obras__titles--box text-center pb-5 pt-0'>Otros Proyectos</h3>
                        <div className='row d-flex justify-content-center'>
                        <ObrasRealizadasItem 
                            alt = {'Anillo'}
                            text = {'Anillo'}
                            img = {'../images/divisiones/division-banners/divisionBanners-anillos.jpg'}
                            link = {'/obras-civiles-industriales/anillo/'}
                        /> 
                        <ObrasRealizadasItem 
                            alt ={'Bancales industriales'}
                            img ={'./images/divisiones/obras-civiles-industriales/bancales-industriales/bancales-industriales-00.jpg'}
                            link = {'/obras-civiles-industriales/bancales-industriales/'}
                            text={'Bancales Industriales'}
                        />
                        <ObrasRealizadasItem 
                            alt ={'Bancales para cañerías'}
                            img ={'./images/divisiones/obras-civiles-industriales/bancales-para-canerias/bancales-para-canierias-00.jpg'}
                            link = {'/obras-civiles-industriales/bancales-para-canerias/'}
                            text ={'Bancales para cañerías'}
                        /> 
                        <ObrasRealizadasItem 
                            alt ={'Cámara drenaje de purgas'}
                            img ={'./images/divisiones/obras-civiles-industriales/camara-drenaje-de-purgas/camara-drenaje-purgas-00.jpg'}
                            link = {'/obras-civiles-industriales/camara-drenaje-de-purgas/'}
                            text={'Cámara drenaje de purgas'}
                        /> 
                        <ObrasRealizadasItem 
                            alt ={'Veredas'}
                            img ={'./images/divisiones/obras-civiles-industriales/veredas/veredas-00.jpg'}
                            link = {'/obras-civiles-industriales/veredas/'}
                            text={'Veredas'}
                        /> 
                    </div>
                </div>
            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default PisosTanques