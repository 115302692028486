import React from 'react';
import './Loading.scss'

const LoadingCarousel = () => {

  return  (
      <div className='container text-center p-5' style={{ margin: '0 auto'}}>
        <i className="fas fa-spinner fa-spin fa-3x"></i>
      </div>)

};

export default LoadingCarousel;
