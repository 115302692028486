import React from 'react'
import './Footer.scss';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className='container-fluid footer__back'>
            <div className='row'>
                
                <div className='col-md-6 pt-5'>
                    <div className='formBloques'>
                        <div className='formBloques__container'>
                            <p className='formBloques__tit'>Direccion</p>
                            <p className='formBloques__txt'>
                                Marcial Riadigos 524<br />
                                Comodoro Rivadavia<br />
                                Chubut Argentina
                            </p>
                        </div>
                        <div className='formBloques__container'>
                            <p className='formBloques__tit'>Horario</p>
                            <p className='formBloques__txt'>
                                Lunes a Viernes:<br />
                                Mañana: 8 a 12:00 Hrs.<br />
                                Tarde: 13:00 a 17:00 Hrs<br />
                                Sábados: 8 a 12:00 Hrs	<br /> 
                            </p>
                        </div>
                        <div className='formBloques__container'>
                            <p className='formBloques__tit'>Contacto</p>
                            <p className='formBloques__txt'>
                                <i className="fas fa-phone footer__icon fa-1x"></i> +54 9 2975 92-6719<br />
                                <i className="fas fa-phone footer__icon fa-1x"></i> +54 9 2975 94-8417<br /> 
                                <i className="fas fa-mobile-alt footer__icon" style={{ marginLeft: '3px', marginRight: '5px'}}></i> 0297 448-1759<br />
                                <i className="fas fa-mobile-alt footer__icon" style={{ marginLeft: '3px', marginRight: '5px'}}></i> 0297 448-1870<br />
                                <Link to={''} className='link_txt'>consultas@c-integrales.com.ar<br /> </Link>	
                            </p>
                        </div>
                    </div>
                    <ul className='formItems'>
                        <Link to={'/politica-integral/'} className='link_txt'><li className='formItems__link'>POLITICA INTEGRAL</li> </Link>	
                        <Link to={'/trabaja-con-nosotros/'} className='link_txt'><li className='formItems__link'>TRABAJA CON NOSOTROS</li></Link>
                    </ul>
                </div>
                
                <div className='col-md-6 p-0 formItems__image'>
                    <img src="../images/locacion-footer.jpg" className='d-block w-100' alt="Base de la empresas" />
                </div>
            
            </div>
        </footer>
    )
}

export default Footer
