import React, {useState, useEffect} from 'react';
import DivisionThumb from './DivisionThumb/DivisionThumb'
import DivisionContainerInternas from '../DivisionContainer/DivisionContainerInternas'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './Divisiones.scss';
import './DivisionesDetalles.scss'

const ArmadoAndamios = () => {

    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return    <section className='container-fluid p-0 pb-0 display-block'>
                
                <Modal
                    show={show}
                    size='lg'
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    >
                    <Modal.Header closeButton>
                        <Modal.Title>Techo: Reparacion y Construccion .</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
                    </Modal.Body>

                    <Modal.Footer>
                        <small >{txtDes}</small>
                    </Modal.Footer>
                </Modal>

                <div className='divisionItem__container animated fast fadeIn'>
                    <img src="../images/headers/headers-armado-andamios.jpg" 
                        alt="Armado de Andamios." 
                        loading='lazy'
                        className='divisionItem__container--img'    
                    />

                    <div className='divisionItem__container--textos divisionItem__container--textos-2-lineas' >
                        <h3 className='divisionItem__container--header'>DIVISION</h3>
                        <h1 className='divisionItem__container--title '>Armado de Andamios</h1>
                    </div>
                </div>
                

                <div className='divisionItem__detail  animated fast fadeIn'>
                    <div className='container container__divisiones'>
                        <Link to={'/'}>
                            <small> 
                                <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                            Volver a Inicio
                            </small>
                        </Link>

                        <div className='row'>
                            
                            <div className='col-md-6'> 
                            <img 
                                src="../images/divisiones/armado-andamios/andamios-00.jpg" 
                                alt="Reparacion de techo de tanque, obra terminada."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            /> 
                            </div>

                            <div className='col-md-6 divisionItem__detail--block'>
                                <h1 className='divisionItem__detail--title' >Armado de andamios</h1>
                                <p  className='divisionItem__detail--text' > 
                                Desarrollamos el alquiler de plataformas de trabajo elevadas temporales  para la construcción y la industria del petróleo según los requerimientos del cliente. El alquiler incluye diseño, cálculo estructural y montaje de los andamios puestos en obra.<br /><br />
                                Las estructuras de andamios, dependiendo de la accesibilidad, se realizan mediante cuerpos de andamios industrializados o andamios personalizados mediante nudos y barras.<br /><br />
                                En ambos casos, los andamios cumplen con los criterios de seguridad y estabilidad estructural adecuándose a las condiciones de servicio y ambientales.

                                </p> 
                            </div>
                            
                        </div>

                        <div className='gallery__cont'>

                            <div className='gallery__cont--column'>
                                <img    onClick={ () => handleShow('../images/divisiones/armado-andamios/andamios-01.jpg', 'Descripcion de la foto.') }  
                                        src='../images/divisiones/armado-andamios/andamios-01.jpg' alt='Reparacion de techo' loading='lazy'/>
                                <img    onClick={ () => handleShow('../images/divisiones/armado-andamios/andamios-02.jpg', 'Descripcion de la foto.') }  
                                        src='../images/divisiones/armado-andamios/andamios-02.jpg' alt='Reparacion de techo' loading='lazy' />
                                
                            </div>

                            <div className='gallery__cont--column'>
                                <img    onClick={ () => handleShow('../images/divisiones/armado-andamios/andamios-04.jpg', 'Descripcion de la foto.') }
                                        src='../images/divisiones/armado-andamios/andamios-04.jpg' alt='Reparacion de techo' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/armado-andamios/andamios-05.jpg', 'Descripcion de la foto.') }
                                        src='../images/divisiones/armado-andamios/andamios-05.jpg' alt='Reparacion de techo' loading='lazy' />
                            </div>

                            <div className='gallery__cont--column'>
                                <img    onClick={ () => handleShow('../images/divisiones/armado-andamios/andamios-10.jpg', 'Descripcion de la foto.') }
                                        src='../images/divisiones/armado-andamios/andamios-10.jpg' alt='Reparacion de techo' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/armado-andamios/andamios-08.jpg', 'Descripcion de la foto.') }
                                        src='../images/divisiones/armado-andamios/andamios-08.jpg' alt='Reparacion de techo' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/armado-andamios/andamios-01.jpg', 'Descripcion de la foto.') } 
                                        src='../images/divisiones/armado-andamios/andamios-03.jpg' alt='Reparacion de techo' loading='lazy' />
                                
                            </div>

                            <div className='gallery__cont--column'>
                                <img    onClick={ () => handleShow('../images/divisiones/armado-andamios/andamios-11.jpg', 'Descripcion de la foto.') }
                                        src='../images/divisiones/armado-andamios/andamios-11.jpg' alt='Reparacion de techo' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/armado-andamios/andamios-06.jpg', 'Descripcion de la foto.') }
                                        src='../images/divisiones/armado-andamios/andamios-06.jpg' alt='Reparacion de techo' loading='lazy' /> 
                            
                            </div>

                            <div className='gallery__cont--column'>
                            
                                <img    onClick={ () => handleShow('../images/divisiones/armado-andamios/andamios-07.jpg', 'Descripcion de la foto.') }
                                        src='../images/divisiones/armado-andamios/andamios-07.jpg' alt='Reparacion de techo' loading='lazy' />
                                <img    onClick={ () => handleShow('../images/divisiones/armado-andamios/andamios-09.jpg', 'Descripcion de la foto.') }
                                        src='../images/divisiones/armado-andamios/andamios-09.jpg' alt='Reparacion de techo' loading='lazy' />
                            </div> 
                        
                        </div>
                    </div>
                </div>
                
                <div>
                    <h3 className='division__bajada--tit text-center'>
                        Otras divisiones
                    </h3>
                    <div>
                        <DivisionContainerInternas divSelect={'armado-andamios'} />
                    </div>
                </div>

                <FooterFormulario className='mt-5' />
            </section>

};

export default ArmadoAndamios;
