import React, {useState, useEffect} from 'react'
import Loading from '../../Commons/Loading/Loading';
import FooterFormulario from '../../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import '../DivisionesDetalles.scss'
import DivisionBanner from '../../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'


const RecubrimientoChapa = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Cintas calefactoras.</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>
            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-aislaciones-termicas.jpg" 
                    alt="Reparacion de Tanques." 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos' >
                    <h3 className='divisionItem__container--header'>DIVISION</h3>
                    <h1 className='divisionItem__container--title'>AILSACIONES TERMICAS</h1>
                    
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/aislaciones-termicas/'}>
                    <small> 
                        <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                        Volver a Division</small>
                    </Link>

                    <div className='row'>
                        <div className='col-md-4'> 
                            
                            <img 
                                src="../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-07.jpg" 
                                alt="Tanque de 50.000m3. con la obra terminada."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            /> 

                        </div>

                        <div className='col-md-8 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Aislamiento térmico</h1>
                            <p  className='divisionItem__detail--text' > 
                                Desarrollamos aislamiento térmico con el objetivo de:<br/>
                                Reducir la cantidad de energía necesaria para mantener el equilibrio del proceso, es decir reduciendo las perdidas de calor las cuales se traducen en ahorros energéticos significativos.<br />
                                <br/>
                                Minimizar incidentes y accidentes, es decir, las temperaturas superficiales externas pueden ser elevadas y provocar lesiones. Asimismo, podrían producirse efectos de combustión e incendio en materiales combustibles próximos a estas superficies.<br/><br/>
                                Mejorar el proceso, o sea, deben evitarse transferencias térmicas que provoquen un mal funcionamiento del proceso por diferencias de temperaturas no admisibles. Esta estabilidad térmica se consigue con el aislamiento.<br/><br/>
                                Minimizar el impacto medioambiental, esto quiere decir que, el aislamiento al reducir la cantidad de energía necesaria para el proceso, también se reducen las emisiones de CO2. Asimismo, en ciertas industrias, se busca la reducción del nivel de ruido, el aislamiento tiene dos objetivos: proteger los oídos del personal que trabaja cerca y reducir el sonido ambiente en el entorno local, sobre todo cuando se sitúa en áreas urbanas.  <br/><br/>
                                En base a su aplicación, es la selección de la composición de los materiales. Los más utilizados son la combinación de lana mineral y chapa de aluminio o acero galvanizado.
                                <br/>
                            </p>
                        </div>

                    </div>

                    <div className='gallery__cont'>
                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-01.jpg', 'Recubrimiento en chapa.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-01.jpg' alt='Recubrimiento en chapa.' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-02.jpg', 'Recubrimiento en chapa.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-02.jpg' alt='Recubrimiento en chapa.' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-03.jpg', 'Recubrimiento en chapa.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-03.jpg' alt='Recubrimiento en chapa.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-04.jpg', 'Recubrimiento en chapa.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-04.jpg' alt='Recubrimiento en chapa.' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-05.jpg', 'Recubrimiento en chapa.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-05.jpg' alt='Recubrimiento en chapa.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-06.jpg', 'Recubrimiento en chapa.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-06.jpg' alt='Recubrimiento en chapa.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-07.jpg', 'Recubrimiento en chapa.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-07.jpg' alt='Recubrimiento en chapa.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-08.jpg', 'Recubrimiento en chapa.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-08.jpg' alt='Recubrimiento en chapa.' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-09.jpg', 'Recubrimiento en chapa.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-09.jpg' alt='Recubrimiento en chapa.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-10.jpg', 'Recubrimiento en chapa.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-10.jpg' alt='Recubrimiento en chapa.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-11.jpg', 'Recubrimiento en chapa.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-11.jpg' alt='Recubrimiento en chapa.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-12.jpg', 'Recubrimiento en chapa.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-12.jpg' alt='Recubrimiento en chapa.' loading='lazy' />
                        </div>   

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-13.jpg', 'Recubrimiento en chapa.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-13.jpg' alt='Recubrimiento en chapa.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-15.jpg', 'Recubrimiento en chapa.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-15.jpg' alt='Recubrimiento en chapa.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-16.jpg', 'Recubrimiento en chapa.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-16.jpg' alt='Recubrimiento en chapa.' loading='lazy' />
                        </div>   

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-14.jpg', 'Recubrimiento en chapa.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-14.jpg' alt='Recubrimiento en chapa.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-17.jpg', 'Recubrimiento en chapa.') } src='../images/divisiones/aislaciones-termicas/recubrimiento-chapa/recubrimiento-chapa-17.jpg' alt='Recubrimiento en chapa.' loading='lazy' />
                        </div>   
                    </div>
                </div>
            </div>
            
            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default RecubrimientoChapa