import React , {useEffect, useState}   from 'react'
import Loading   from '../Commons/Loading/Loading'
import DivisionThumb from '../Divisiones/DivisionThumb/DivisionThumb'
import DivisionContainerInternas from '../DivisionContainer/DivisionContainerInternas'
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'

import '../Divisiones/Divisiones.scss';

const Obras = () => {

    const [loading, setLoading] = useState(true);

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return    <section className='container-fluid p-0 pb-0 display-block'>
                
                <div className='division__container animated fast fadeIn'>
                   
                    <img 
                        src="../images/headers/headers-obras-civiles.jpg" 
                        alt="Radiadores industriales." 
                        loading='lazy'
                        className='division__container--img'
                    />

                    <div className='division__container--textos' >
                        {/* <h3 className='division__container--header'></h3> */}
                        <h1 className='division__container--title'>Obras</h1>
                        <p className='division__container--text'>
                        Nuestra amplia capacidad y equipamientos nos da la oportunidad de resolver distintos tipos de trabajos.
                        Entre ellos proyectos civiles, metalúrgicos, montaje y desmontaje, etc. 
                        <br /><br />
                        Contamos con un gran equipo logístico que permite nuestra organización en las distintas ramas. Nos enfocamos en la seguridad, calidad y medio ambiente de cada obra. 
                        </p>
                    </div>

                </div>

                <div className='container container__divisiones'>
                    
                    <div className='text-center division__bajada--down' >
                        <i className="fas fa-angle-down fa-3x"></i>
                    </div>
                                        
                    <div className='row justify-content-center proyecto__containers'>
                            <DivisionThumb 
                                imgUrl ={'./images/obras/aislacion-lh3/aislacion-lh3-00.jpg'}
                                division= {'Aislaciones termicas'}
                                titulo ={'Aislacion de LH03'}
                                linkUrl = {'/obras/aislacion-de-lh03'}
                                altImg={'Aislacion de LH03'}
                                style={{marginBottom : '20px'}}
                            />
                             <DivisionThumb 
                                imgUrl ={'./images/obras/aislacion-piu/aislacion-piu-01.jpg'}
                                division= {'Aislaciones termicas'}
                                titulo ={'Aislacion en PIU'}
                                linkUrl = {'/obras/aislacion-en-piu'}
                                altImg={'Aislacion en PIU'}
                                style={{marginBottom : '20px'}}
                            />
                             <DivisionThumb 
                                imgUrl ={'./images/obras/spool/spool-00.jpg'}
                                division= {'Obras civiles'}
                                titulo ={'Spool'}
                                linkUrl = {'/obras/spool'}
                                altImg={'Spool'}
                                style={{marginBottom : '20px'}}
                            />
                             {/* <DivisionThumb 
                                imgUrl ={'./images/obras/enfriador-aceite/enfriador-aceite-00.jpg'}
                                division= {'Radiadores'}
                                titulo ={'Enfriador de aceite'}
                                linkUrl = {'/obras/enfriador-de-aceite'}
                                altImg={'Enfriador de aceite'}
                                style={{marginBottom : '20px'}}
                            /> */}
                             <DivisionThumb 
                                imgUrl ={'./images/obras/radiador-guascor/radiador-guascor.jpg'}
                                division= {'Radiadores'}
                                titulo ={'Radiador Guascor FG240'}
                                linkUrl = {'/obras/radiador-guascor-fg-24/'}
                                altImg={'Radiador Guascor FG240'}
                                style={{marginBottom : '20px'}}
                            />
                             <DivisionThumb 
                                imgUrl ={'./images/obras/capsa-capex/capsa-capex-00.jpg'}
                                division= {'Obras Civiles'}
                                titulo ={'Mantenimiento RCI'}
                                linkUrl = {'/obras/capsa-capex'}
                                altImg={'Mantenimiento RCI'}
                                style={{marginBottom : '20px'}}
                            />

                            
                            

                            <DivisionThumb 
                                imgUrl ={'./images/obras/andamios-20m/andamio.jpg'}
                                division= {'Armado de Andamios'}
                                titulo ={'Andamio > 20 metros de altura'}
                                linkUrl = {'/obras/andamio-20m'}
                                altImg={'Andamio >20 metros de altura'}
                                style={{marginBottom : '20px'}}
                            />
                            <DivisionThumb 
                                imgUrl ={'./images/obras/obra-civil-antares/antares-02.jpg'}
                                division= {'Obras Civiles'}
                                titulo ={'Obra Civil Antares'}
                                linkUrl = {'/obras/obra-civil-antares'}
                                altImg={'Obra Civil Antares'}
                            />
                            <DivisionThumb 
                                imgUrl ={'./images/obras/tanque-5000m/tanque-5000m-01.jpg'}
                                division= {'Reparación de Tanques'}
                                titulo ={'Tanque 5.000 m³'}
                                linkUrl = {'/obras/tanque-5000m/'}
                                altImg={'Tanque 5.000 m³'}
                            />
                            <DivisionThumb 
                                imgUrl ={'./images/obras/tanque-10000m/tanque-1000m-01.jpg'}
                                division= {'Reparación de Tanques'}
                                titulo ={'Tanque 10.000 m³'}
                                linkUrl = {'/obras/tanque-10000m/'}
                                altImg={'Tanque 10.000 m³'}
                            />
                            <DivisionThumb 
                                imgUrl ={'./images/obras/tanque-20000m/tanque-2000m-01.jpg'}
                                division= {'Reparación de Tanques'}
                                titulo ={'Tanque 20.000 m³'}
                                linkUrl = {'/obras/tanque-20000m/'}
                                altImg={'Tanque 20.000 m³'}
                            />
                            <DivisionThumb 
                                imgUrl ={'./images/obras/tanque-50000m/tanque-50000m-00.jpg'}
                                division= {'Reparación de Tanques'}
                                titulo ={'Tanque 50.000 m³'}
                                linkUrl = {'/obras/tanque-50000m/'}
                                altImg={'Tanque 50.000 m³'}
                            />
                            <DivisionThumb 
                                imgUrl ={'./images/obras/telecomunicaciones/banco-galicia-plaza.jpg'}
                                division= {'Telecomunicaciones'}
                                titulo ={'Domotica'}
                                linkUrl = {'/obras/banco-galicia-plaza/'}
                                altImg={'Obra para Banco Galicia'}
                            />
                            <DivisionThumb 
                                imgUrl ={'./images/obras/telecomunicaciones/enlaces-en-rada-tilly.jpg'}
                                division= {'Telecomunicaciones'}
                                titulo ={'Enlaces en Rada Tilly'}
                                linkUrl = {'/obras/enlaces-en-rada-tilly/'}
                                altImg={'Enlaces en Rada Tilly'}
                            />
                            {/* <DivisionThumb 
                                imgUrl ={'./images/obras/telecomunicaciones/enlace-punto-a-punto-de-largos-vanos.jpg'}
                                division= {'Telecomunicaciones'}
                                titulo ={'Enlace Punto a Punto de largos Vanos '}
                                linkUrl = {'/obras/enlace-punto-a-punto-de-largos-vanos/'}
                                altImg={'Enlace Punto a Punto de largos Vanos'}
                            />
                             <DivisionThumb 
                                imgUrl ={'./images/obras/telecomunicaciones/cooperativa-de-batan-bs-as.jpg'}
                                division= {'Telecomunicaciones'}
                                titulo ={'Cooperativa de Batán, Bs As. '}
                                linkUrl = {'/obras/cooperativa-de-batan-bs-as/'}
                                altImg={'Cooperativa de Batán, Bs As. '}
                            /> */}
                             {/* <DivisionThumb 
                                imgUrl ={'./images/obras/telecomunicaciones/coop-de-laguna-de-los-padres.jpg'}
                                division= {'Telecomunicaciones'}
                                titulo ={'Coop de Laguna de los Padres'}
                                linkUrl = {'/obras/coop-de-laguna-de-los-padres/'}
                                altImg={'Coop de Laguna de los Padres'}
                            /> */}
                             <DivisionThumb 
                                imgUrl ={'./images/obras/telecomunicaciones/fo-para-largos-vanos.jpg'}
                                division= {'Telecomunicaciones'}
                                titulo ={'FO para Largos Vanos'}
                                linkUrl = {'/obras/fo-para-largos-vanos/'}
                                altImg={'FO para Largos Vanos'}
                            />
                             <DivisionThumb 
                                imgUrl ={'./images/obras/telecomunicaciones/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua.jpg'}
                                division= {'Telecomunicaciones'}
                                titulo ={'Red DWDM Gran Ancho de Banda FO Zero Pico de Agua.'}
                                linkUrl = {'/obras/red-dwdm-gran-ancho-de-banda-fo-zero-pico-de-agua/'}
                                altImg={'Red DWDM Gran Ancho de Banda FO Zero Pico de Agua.'}
                            />
                            
                    </div>
                </div>

                <FooterFormulario className='mt-5' />
            </section>

};

export default Obras;
