import React from 'react'
import Button from '../../Commons/Button/Button'
import './DivisionBanner.scss'
import { Link } from 'react-router-dom'

const DivisionBanner = ( props ) => {

    const divStyle = {
        backgroundImage: 'url(' + props.imgUrl + ')',
    }
    
    return (
        <div style={divStyle} className={`bannerContainer  banner__background`}>
            <p className='banner__texto'>{props.division}</p>
            <Link to={`${props.linkUrl}`}>
                <div className='banner__over'>
                    <Button toDo='Mas info' type='button'/>
                </div>
            </Link>
        </div>
    )
}

export default DivisionBanner
