import React, {useState, useEffect} from 'react'
import Loading from '../../Commons/Loading/Loading';
import FooterFormulario from '../../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import ObrasRealizadasItem from '../../Commons/ObrasRealizadas/ObrasRealizadasItem';
import { Modal } from 'react-bootstrap'

import '../../../Styles/main.scss'
import '../DivisionesDetalles.scss'

const DtsDas = () => {

    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Fibra Óptica</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>

              <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer>
            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-networking.jpg" 
                    alt="Fibra Optica para DTS y DAS." 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-2-lineas' >
                    <h3 className='divisionItem__container--header'>DIVISION</h3>
                    <h1 className='divisionItem__container--title'>TELECOMUNICACIONES</h1>
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>

                <div className='container animated fast fadeIn'>
                    <Link to={'/telecomunicaciones/'}>
                    <small> 
                        <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                        Volver a Division</small>
                    </Link>

                    <div className='row'>
                    
                        <div className='col-md-4'> 
                            <img 
                                src="../images/divisiones/telecomunicaciones/fibra-optica/enlaces-de-largos-vanos-y-dwdm/imagen-2.jpg" 
                                alt="Dts y Das"
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            /> 
                        </div>

                        <div className='col-md-8 pt-2'>
                            <h1 className='divisionItem__detail--title' >DTS y DAS</h1>
                            <p  className='divisionItem__detail--text' > 
                            La implementación de DTS (Distribución de Temperatura y Sensor) y DAS (Distribución de sensor acústico) se utiliza en minería y petróleo para registrar perfiles de inyección o monitorear las operaciones de estimulación de pozos. La fibra óptica se comporta como una matriz de sensores que permite registrar el perfil de estimulación y producción del pozo continuamente sin necesidad de mover el cable y, por lo tanto, proporcionar información sobre las condiciones de flujo dinámico de una manera en la que los sensores convencionales no pueden.<br /><br />
                            <strong>DTS:</strong> Los datos de temperatura distribuidos determinan los perfiles de inyección a través del análisis de warm-back.<br /><br />
                            <strong> DAS:</strong> Permite crear perfiles de inyección validando sobre los pozos productores multifacéticos la producción de cada face y sector de punción del pozo.<br /><br />
                            Con la aplicación de modelos de cálculo somos capaces de crear perfiles de inyección o estimulación midiendo la respuesta acústica del pozo. <br /><br />
                            El software de campo permite la visualización integrada en tiempo real de la temperatura y los datos acústicos espectrales para el control de calidad y la evaluación inicial, pero aplicando una plataforma de post procesamiento facilita el análisis avanzado de datos y las opciones de trazado versátiles para que las respuestas corroborantes de mediciones independientes, DAS y DTS, se puedan presentar como información legible para el usuario.<br /><br />
                            </p> 
                        </div>

                    </div>

                    <div className='gallery__cont mt-5'>

                        <div className='gallery__cont--column50'>
                            <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/fibra-optica/dts-y-das/imagen-1.jpg', 'Fibra Optica para DTS y DAS') } src='../images/divisiones/telecomunicaciones/fibra-optica/dts-y-das/imagen-1.jpg' alt='Fibra Optica para DTS y DAS.' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/fibra-optica/dts-y-das/imagen-3.jpg', 'Fibra Optica para DTS y DAS') } src='../images/divisiones/telecomunicaciones/fibra-optica/dts-y-das/imagen-3.jpg' alt='Fibra Optica para DTS y DAS.' loading='lazy' />
                        </div>
                        <div className='gallery__cont--column50'>
                            <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/fibra-optica/dts-y-das/imagen-2.jpg', 'Fibra Optica para DTS y DAS') } src='../images/divisiones/telecomunicaciones/fibra-optica/dts-y-das/imagen-2.jpg' alt='Fibra Optica para DTS y DAS.' loading='lazy' />
                        </div>

                       
                    </div>

                </div>
            </div>

            <div className='container-fluid otros__proyectos'>
                <div className='container'>
                    <h3 className='obras__titles obras__titles--box text-center pb-5 pt-0'>Otros Proyectos</h3>
                        <div className='row d-flex justify-content-center'>
                        <ObrasRealizadasItem 
                            alt = {'Fibra Optica'}
                            text = {'Fibra Optica'}
                            img = {'../images/divisiones/division-banners/divisionBanners-telecomunicaciones-fibra-optica.jpg'}
                            link = {'/telecomunicaciones/fibra-optica/'}
                        /> 
                        <ObrasRealizadasItem 
                            alt = {'FTTx'}
                            text = {'FTTx'}
                            img = {'../images/divisiones/division-banners/divisionBanners-telecomunicaciones-fttx.jpg'}
                            link = {'/telecomunicaciones/fttx/'}
                        /> 
                        <ObrasRealizadasItem 
                            alt = {'Networking'}
                            text = {'Networking'}
                            img = {'../images/divisiones/division-banners/divisionBanners-telecomunicaciones-networking.jpg'}
                            link = {'/telecomunicaciones/networking/'}
                        /> 
                        <ObrasRealizadasItem 
                            alt = {'Video Vigilancia'}
                            text = {'Video Vigilancia'}
                            img = {'../images/divisiones/division-banners/divisionBanners-telecomunicaciones-video-vigilancia.jpg'}
                            link = {'/telecomunicaciones/video-vigilancia/'}
                        /> 
                        <ObrasRealizadasItem 
                            alt = {'Automatización y Domótica'}
                            text = {'Automatización y Domótica'}
                            img = {'../images/divisiones/division-banners/divisionBanners-telecomunicaciones-automatizacion-domotica.jpg'}
                            link = {'/telecomunicaciones/automtizacion-y-domotica/'}
                        /> 
                    </div>
                </div>
            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default DtsDas