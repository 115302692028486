import React, {useState, useEffect} from 'react'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import DivisionBanner from '../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'

import '../../Styles/main.scss'
import '../Divisiones/DivisionesDetalles.scss'
import OtrasObras from '../Commons/ObrasRealizadas/OtrasObras';

const Andamio20M = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Andamio &#62;20 metros de altura.</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>

              <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer>

            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-armado-andamios.jpg" 
                    alt="Obras, andamios." 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-obras' >
                    <h3 className='divisionItem__container--header'>OBRAS</h3>
                    <h1 className='divisionItem__container--title'>Andamio<br />&#62;20 metros de altura.</h1>
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/obras/'}>
                        <small> 
                        <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                        Volver a Obras
                        </small>
                    </Link>

                    <div className='row'>

                        <div className='col-md-6'>     
                            <img 
                                src="../images/obras/andamios-20m/andamio.jpg" 
                                alt="Andamio >20 metros de altura."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            /> 
                        </div>

                        <div className='col-md-6 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Andamio &#62;20 metros de altura.</h1>
                            <h3 className='divisionItem__detail--subtitle' >
                                <i className="fas fa-map-marker-alt"  style={{'paddingBottom':'15px', 'paddingRight': '10px'}}></i>
                                Obra: CC Cerro Dragón
                            </h3>
                            <p  className='divisionItem__detail--text' > 
                                Contamos con andamios industrializados reforzados con columnas y largueros tubulares 
                                mediante espigas de unión, nudos forjados fijos y móviles electrozincados.<br />
                                Se realiza el diseño y cálculo necesario para cada tipo de trabajo.<br />
                                La plataforma de trabajo consta de tablones metálicos encastrables antideslizantes, barandas y guarda pie de seguridad, se dispuso dos plataformas 
                                intermedias de acuerdo a la altura requerida. 
                            </p>
                            <p  className='divisionItem__detail--text' > 
                                <ul>
                                    <li>Carga máx. de operación: 300 kg </li>
                                    <li>Operarios sobre plataforma: 2 UN</li>
                                    <li>Ø caño: 50mm</li>
                                    <li>Tipo de acero: F24</li>
                                </ul>
                            </p>
                            
                        </div>

                    </div>

                    <div className='gallery__cont'>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/andamios-20m/andamio-01.jpg', 'Descripcion de la foto.') } src='../images/obras/andamios-20m/andamio-01.jpg' alt='Andamios mayores a 20m' loading='lazy'/>
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/andamios-20m/andamio-02.jpg', 'Descripcion de la foto.') } src='../images/obras/andamios-20m/andamio-02.jpg' alt='Andamios mayores a 20m' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/andamios-20m/andamio-05.jpg', 'Descripcion de la foto.') } src='../images/obras/andamios-20m/andamio-05.jpg' alt='Andamios mayores a 20m' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/andamios-20m/andamio-01.jpg', 'Descripcion de la foto.') } src='../images/obras/andamios-20m/andamio-03.jpg' alt='Andamios mayores a 20m' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/andamios-20m/andamio-04.jpg', 'Descripcion de la foto.') } src='../images/obras/andamios-20m/andamio-04.jpg' alt='Andamios mayores a 20m' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/andamios-20m/andamio-06.jpg', 'Descripcion de la foto.') } src='../images/obras/andamios-20m/andamio-06.jpg' alt='Andamios mayores a 20m' loading='lazy' /> 
                        </div>

                    </div>
                </div>
            </div>
            <div>
                <div className='row divisionItem__proyectosDestacados p-0'> 
                    <OtrasObras  itemToShowParam={5} itemsToShowParam={5} />
                </div>
            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default Andamio20M