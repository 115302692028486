import React , {useEffect, useState} from 'react'
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario';
import Loading from '../Commons/Loading/Loading'
import './Contacto.scss'

const TrabajaConNosotros = () => {

    const [loading, setLoading] = useState(true);

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return <section className='container-fluid p-0 containerBlock' style={{backgroundColor : '#222222'}}> 
                
                <div className='divisionItem__container animated fast fadeIn'>
                    <img src="../images/headers/headers-trabaja-con-nosotros.jpg" 
                        alt="Armado de Andamios." 
                        loading='lazy'
                        className='divisionItem__container--img'    
                    />
                    <div className='divisionItem__container--textos divisionItem__container--textos-2-lineas' >
                        <h3 className='divisionItem__container--header'>Escribinos</h3>
                        <h1 className='divisionItem__container--title '>Trabaja con nosotros</h1>
                    </div>
                </div>

                <div className='contact__container row justify-content-center' style={{width: '100%'}}>
                    
                    <div className='col-md-10'>
                        <p  className='contact__bajada'>
                        La búsqueda de la excelencia y la mejora continua se basan en el valor que aportan las personas.<br />
                        Construcciones Integrales constantemente suma nuevos talentos, que hacen crecer a esta gran familia.<br /> <br />
                        ¿Querés ser parte de CI?
                        </p>
                        <p  className='contact__text contact__bajada'>
                        Te invitamos a completar los siguientes datos y anexar tu CV.<br />
                        ¡Muchas gracias!
                        </p>
                    </div>

                </div>
                
                <FooterFormulario formOrigin={'trabajaConNosotros'} />

            </section>;

};
export default TrabajaConNosotros;
