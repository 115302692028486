import React, {useState, useEffect} from 'react'
import Loading from '../../Commons/Loading/Loading';
import FooterFormulario from '../../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import ObrasRealizadasItem from '../../Commons/ObrasRealizadas/ObrasRealizadasItem';
import { Modal } from 'react-bootstrap'

import '../../../Styles/main.scss'
import '../DivisionesDetalles.scss'

const CaneriasAccesorios = () => {

    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Cañerias y accesorios</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>

              <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer>
            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-canerias-y-accesorios.jpg" 
                    alt="Still Pipe" 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-2-lineas' >
                    <h3 className='divisionItem__container--header'>DIVISION</h3>
                    <h1 className='divisionItem__container--title'>REPARACION DE TANQUES</h1>
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>
                <div className='container animated fast fadeIn'>
                <Link to={'/reparacion-tanques/'}>
                <small> 
                    <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                    Volver a Division</small>
                </Link>

                <div className='row'>
                    <div className='col-md-6'> 
                        
                        <img 
                            src="../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-01.jpg" 
                            alt="Se realizan tubos para medición, sondeo manual y radar.."
                            className='d-block w-100 divisionItem__detail--header'
                            loading='lazy'
                        /> 
                    </div>

                    <div className='col-md-6 divisionItem__detail--block'>
                        <h1 className='divisionItem__detail--title' >Cañerías y Accesorios</h1>
                        <p  className='divisionItem__detail--text' > 
                        Hacemos reparación, diseño, fabricación y montaje de los siguientes componentes del tanque de almacenamiento:<br /><br />
                        <ul>
                            <li>Casilla para Still Pipe.</li>
                            <li>Escaleras a techo y pasarelas sobre líneas.</li>
                            <li>Hot Tap.</li>
                            <li>Puertas de acceso</li>
                            <li>Redes de incendio</li>
                            <li>Purgas</li>
                            <li>Soporteria de cañerías varias.</li>
                            <li>Líneas: succión, producción, alivio, toma muestra, pivot masters.</li>
                            <li>Drenajes de fondo: ollas de fondo.</li>
                            <li>Bocas de techo.</li>
                            <li>Adecuación de columnas de techo fijo y flotante.</li>
                            <li>Barandas en techo. </li>
                            <li>Canalizaciones eléctricas, soportes e iluminación. </li>
                            <li>Otros</li>
                        </ul>

                        </p> 
                        
                    </div>

                </div>

                <div className='gallery__cont'>

                    <div className='gallery__cont--column'>

                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-02.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-02.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-03.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-03.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-18.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-18.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-19.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-19.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-20.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-20.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-01.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-01.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy'/>
                        
                    </div>

                    <div className='gallery__cont--column'>

                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-04.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-04.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-05.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-05.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-16.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-16.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-17.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-17.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-10.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-10.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />

                    </div>

                    <div className='gallery__cont--column'>
                        
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-06.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-06.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-07.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-07.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' /> 
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-14.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-14.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-15.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-15.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-21.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-21.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-22.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-22.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />

                    </div>

                    <div className='gallery__cont--column'>
                        
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-08.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-08.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-12.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-12.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-13.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-13.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-23.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-23.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-24.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-24.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />

                    </div>

                    <div className='gallery__cont--column'>
                       
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-09.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-09.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-11.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-11.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-25.jpg', 'Se realizan tubos para medición, sondeo manual y radar. ') } src='../images/divisiones/reparacion-tanques/canerias-y-accesorios/canierias-accesorios-25.jpg' alt='Hacemos reparación, diseño, fabricación y montaje' loading='lazy' />

                    </div> 
                
                </div>
                </div>
            </div>

            <div className='container-fluid otros__proyectos'>
                <div className='container'>
                    <h3 className='obras__titles obras__titles--box text-center pb-5 pt-0'>Otros Proyectos</h3>
                    <div className='row d-flex justify-content-center'>
                        <ObrasRealizadasItem 
                            alt = {'Arenado y pintura'}
                            img = {'../images/divisiones/division-banners/divisionBanners-arenado-y-pintura.jpg'}
                            link = {'/reparacion-tanques/arenado-pintura/'}
                            text = {'Arenado y pintura'}

                        />
                        <ObrasRealizadasItem 
                            img={'../images/divisiones/division-banners/divisionBanners-piso-reparacion.jpg'}
                            alt={'Piso: Reparación y Construcción'}
                            link={'/reparacion-tanques/piso-reparacion-y-construccion/'}
                            text={'Piso: Reparación y Construcción'}
                        />
                        <ObrasRealizadasItem 
                            alt = {'Techo: Reparación y Construcción'}
                            img = {'../images/divisiones/division-banners/divisionBanners-techo-reparacion.jpg'}
                            link = {'/reparacion-tanques/techo-reparacion-y-construccion/'}
                            text = {'Techo: Reparación y Construcción'}
                        /> 
                        <ObrasRealizadasItem 
                            alt = {'Still Pipe '}
                            img = {'../images/divisiones/division-banners/divisionBanners-still-pipe.jpg'}
                            link = {'/reparacion-tanques/still-pipe/'}
                            text = {'Still Pipe '}
                        /> 
                        <ObrasRealizadasItem 
                            alt = {'P.E.A.D'}
                            img = {'../images/divisiones/division-banners/divisionBanners-pead.jpg'}
                            link = {'/reparacion-tanques/pead/'}
                            text = {'P.E.A.D'}
                        />
                        
                    </div>
                </div>  
            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default CaneriasAccesorios