import React, {useState, useEffect} from 'react'
import Loading from '../../Commons/Loading/Loading';
import FooterFormulario from '../../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import ObrasRealizadasItem from '../../Commons/ObrasRealizadas/ObrasRealizadasItem';
import { Modal } from 'react-bootstrap'

import '../../../Styles/main.scss'
import '../DivisionesDetalles.scss'

const FTTxEnlacesLargosVanosDWDM = () => {

    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Fibra optica</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>

              <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer>
            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-networking.jpg" 
                    alt="FTTx, Enlaces de Largos Vanos y DWDM." 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-2-lineas' >
                    <h3 className='divisionItem__container--header'>DIVISION</h3>
                    <h1 className='divisionItem__container--title'>TELECOMUNICACIONES</h1>
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>

                <div className='container animated fast fadeIn'>
                    <Link to={'/telecomunicaciones/'}>
                    <small> 
                        <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                        Volver a Division</small>
                    </Link>

                    <div className='row mb-5'>
                        <div className='col-md-6'> 
                            <img 
                                src="../images/divisiones/telecomunicaciones/fibra-optica/enlaces-de-largos-vanos-y-dwdm/imagen-2.jpg" 
                                alt="FTTx"
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            /> 
                        </div>

                        <div className='col-md-6 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >FTTx</h1>
                            <p  className='divisionItem__detail--text' > 
                                EPoseemos una avanzada gama de herramientas y personal preparado para implementar 
                                proyectos de infraestructura y datos, con la mayor eficiencia y calidad de la zona. 
                                Siendo esta la tecnología para el acceso de banda ancha sobre fibra óptica que 
                                sustituye total o parcial el cobre del bucle de acceso. 
                                Las distintas combinaciones desplegadas como FTTN, FTTC, FTTB, FTTH, etc 
                                (siendo la última letra que quien caracteriza el destino de la fibra como: nodo, acera, edificio, hogar).
                            </p> 
                        </div>

                    </div>

                    <div className='gallery__cont'>

                        <div className='gallery__cont--column33'>
                            <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/fibra-optica/enlaces-de-largos-vanos-y-dwdm/imagen-1.jpg', 'FTTx, Enlaces de Largos Vanos y DWDM') } src='../images/divisiones/telecomunicaciones/fibra-optica/enlaces-de-largos-vanos-y-dwdm/imagen-1.jpg' alt='FTTx, Enlaces de Largos Vanos y DWDM.' loading='lazy'/>
                        </div>

                        <div className='gallery__cont--column33'>
                            <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/fibra-optica/enlaces-de-largos-vanos-y-dwdm/imagen-2.jpg', 'FTTx, Enlaces de Largos Vanos y DWDM') } src='../images/divisiones/telecomunicaciones/fibra-optica/enlaces-de-largos-vanos-y-dwdm/imagen-2.jpg' alt='FTTx, Enlaces de Largos Vanos y DWDM.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/fibra-optica/enlaces-de-largos-vanos-y-dwdm/imagen-3.jpg', 'FTTx, Enlaces de Largos Vanos y DWDM') } src='../images/divisiones/telecomunicaciones/fibra-optica/enlaces-de-largos-vanos-y-dwdm/imagen-3.jpg' alt='FTTx, Enlaces de Largos Vanos y DWDM.' loading='lazy' />
                        </div>
                        <div className='gallery__cont--column33'>
                            <img    onClick={ () => handleShow('../images/divisiones/telecomunicaciones/fibra-optica/enlaces-de-largos-vanos-y-dwdm/imagen-4.jpg', 'FTTx, Enlaces de Largos Vanos y DWDM') } src='../images/divisiones/telecomunicaciones/fibra-optica/enlaces-de-largos-vanos-y-dwdm/imagen-4.jpg' alt='FTTx, Enlaces de Largos Vanos y DWDM.' loading='lazy' />
                        </div>
                       
                    </div>

                </div>
            </div>

            <div className='container-fluid otros__proyectos'>
                <div className='container'>
                    <h3 className='obras__titles obras__titles--box text-center pb-5 pt-0'>Otros Proyectos</h3>
                        <div className='row d-flex justify-content-center'>
                        <ObrasRealizadasItem 
                            alt = {'Fibra Optica'}
                            text = {'Fibra Optica'}
                            img = {'../images/divisiones/division-banners/divisionBanners-telecomunicaciones-fibra-optica.jpg'}
                            link = {'/telecomunicaciones/fibra-optica/'}
                        /> 
                        <ObrasRealizadasItem 
                            alt = {'Dts y Das'}
                            text = {'Dts y Das'}
                            img = {'../images/divisiones/division-banners/divisionBanners-telecomunicaciones-dts-das.jpg'}
                            link = {'/telecomunicaciones/dts-das/'}
                        /> 
                        <ObrasRealizadasItem 
                            alt = {'Networking'}
                            text = {'Networking'}
                            img = {'../images/divisiones/division-banners/divisionBanners-telecomunicaciones-networking.jpg'}
                            link = {'/telecomunicaciones/networking/'}
                        /> 
                        <ObrasRealizadasItem 
                            alt = {'Video Vigilancia'}
                            text = {'Video Vigilancia'}
                            img = {'../images/divisiones/division-banners/divisionBanners-telecomunicaciones-video-vigilancia.jpg'}
                            link = {'/telecomunicaciones/video-vigilancia/'}
                        /> 
                        <ObrasRealizadasItem 
                            alt = {'Automatización y Domótica'}
                            text = {'Automatización y Domótica'}
                            img = {'../images/divisiones/division-banners/divisionBanners-telecomunicaciones-automatizacion-domotica.jpg'}
                            link = {'/telecomunicaciones/automtizacion-y-domotica/'}
                        /> 
                    </div>
                </div>
            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default FTTxEnlacesLargosVanosDWDM