import React, {useState, useEffect} from 'react'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap'

import '../../Styles/main.scss'
import '../Divisiones/DivisionesDetalles.scss'
import ObraThumb from '../Commons/ObraThumb/ObraThumb';
import OtrasObras from '../Commons/ObrasRealizadas/OtrasObras';

const AislacionDeLH03 = () => {

    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Andamio &#62;20 metros de altura.</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>

              <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer>

            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-aislaciones-termicas.jpg" 
                    alt="Obras, andamios." 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-obras' >
                    <h3 className='divisionItem__container--header'>OBRAS</h3>
                    <h1 className='divisionItem__container--title'>Aislación de LH03</h1>
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/obras/'}>
                        <small> 
                        <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                        Volver a Obras
                        </small>
                    </Link>

                    <div className='row'>
                        <div className='col-md-6'>     
                            <img 
                                src="../images/obras/aislacion-lh3/aislacion-lh3.jpg" 
                                alt="Parques de bombas de inyección.- I ETAPA."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            /> 
                        </div>
                        <div className='col-md-6 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Adecuación planta de inyección de agua para recuperación secundaria. Parques de bombas de inyección.- I ETAPA.</h1>
                            <h3 className='divisionItem__detail--subtitle' >
                                <i className="fas fa-map-marker-alt"  style={{'paddingBottom':'5px', 'paddingRight': '10px'}}></i>
                                RP43 - Las Heras - Santa Cruz
                            </h3>
                            <p  className='divisionItem__detail--text' > 
                            Se ejecutaron los trabajos de adecuación de cañerías con recubrimiento de aislación térmica.<br />
                            Para ellos se diseñó, fabricó y montó todos los elementos necesarios como cajas, codos, patín, curvas, 
                            tee, niples y caños más aislación térmica. Cada pieza es elaborada en la base operativa con operarios idóneos.<br />
                            <strong>Se registraron todos los procesos de calidad como certificación de chapa y lana, entre otros.</strong>                            </p>
                        </div>
                    </div>

                    <div className='row obras__box  justify-content-center'>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb  
                                ObraNum  = {'4600'}
                                ObraVal  = {'m²'}
                                ObraItem = {''}
                                ObraDet  = {'Chapa de aluminio'}
                            />
                        </div>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb  
                                ObraNum  = {'4550'}
                                ObraVal ={'m²'}
                                ObraItem = {''}
                                ObraDet  = {'Lana mineral'}
                            />
                        </div>
                        <div className='col-6 col-sm-3 col-md-6 col-lg-3 obras__card'>
                            <ObraThumb  
                                ObraNum  = {'2150'}
                                ObraVal ={'UN'}
                                ObraItem = {''}
                                ObraDet  = {'Prefabricados únicos'}
                            />
                        </div>
                    </div>

                    <div className='gallery__cont'>
                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/aislacion-lh3/aislacion-lh3-01.jpg', 'aislacion-lh3.') } src='../images/obras/aislacion-lh3/aislacion-lh3-01.jpg' alt='aislacion lh3' loading='lazy'/>
                        </div>
                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/aislacion-lh3/aislacion-lh3-02.jpg', 'aislacion-lh3.') } src='../images/obras/aislacion-lh3/aislacion-lh3-02.jpg' alt='aislacion lh3' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/aislacion-lh3/aislacion-lh3-05.jpg', 'aislacion-lh3.') } src='../images/obras/aislacion-lh3/aislacion-lh3-05.jpg' alt='aislacion lh3' loading='lazy' />
                        </div>
                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/aislacion-lh3/aislacion-lh3-01.jpg', 'aislacion-lh3.') } src='../images/obras/aislacion-lh3/aislacion-lh3-03.jpg' alt='aislacion lh3' loading='lazy' />
                        </div>
                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/aislacion-lh3/aislacion-lh3-04.jpg', 'aislacion-lh3.') } src='../images/obras/aislacion-lh3/aislacion-lh3-04.jpg' alt='aislacion lh3' loading='lazy' />
                        </div>
                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/obras/aislacion-lh3/aislacion-lh3-06.jpg', 'aislacion-lh3.') } src='../images/obras/aislacion-lh3/aislacion-lh3-06.jpg' alt='aislacion lh3' loading='lazy' /> 
                        </div>
                    </div>    
                    
                </div>
            </div>
            <div className='container-fluid p-0 m-0'>
               <OtrasObras  itemToShowParam={0} itemsToShowParam={5} />
            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default AislacionDeLH03