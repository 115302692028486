import React, {useState, useEffect} from 'react'
import Loading from '../../Commons/Loading/Loading';
import FooterFormulario from '../../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import ObrasRealizadasItem from '../../Commons/ObrasRealizadas/ObrasRealizadasItem'; 
import { Modal } from 'react-bootstrap'

import '../../../Styles/main.scss'
import '../DivisionesDetalles.scss'

const Pead = () => {

    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Techo: Reparacion y Construccion .</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>

              <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer>
            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-pead.jpg" 
                    alt="P.E.A.D. Polietileno de alta densidad" 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-2-lineas' >
                    <h3 className='divisionItem__container--header'>DIVISION</h3>
                    <h1 className='divisionItem__container--title'>REPARACION DE TANQUES</h1>
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>
                <div className='container animated fast fadeIn'>
                <Link to={'/reparacion-tanques/'}>
                <small> 
                    <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                    Volver a Division</small>
                </Link>

                <div className='row'>
                    <div className='col-md-6'> 
                        
                        <img 
                            src="../images/divisiones/reparacion-tanques/pead/pead-00.jpg" 
                            alt="Reparacion de techo de tanque, obra terminada."
                            className='d-block w-100 divisionItem__detail--header'
                            loading='lazy'
                        /> 
                    </div>

                    <div className='col-md-6 divisionItem__detail--block'>
                        <h1 className='divisionItem__detail--title' > P.E.A.D.</h1>
                        <p  className='divisionItem__detail--text' > 
                        Desarrollamos el diseño de interconexión entre purgas con cañerías de PEAD (polietileno de alta densidad) en diámetros solicitados por el cliente. Recorremos metros y kilómetros de cañerías en PEAD realizando el zanjeo y su disposición correspondiente respetando el apoyo con su colchón de arena, tapada mínima y electrofusión de cuplas, codos, etc.  
                        </p> 
                        
                    </div>

                </div>

                <div className='gallery__cont'>

                    <div className='gallery__cont--column'>
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/pead/pead-01.jpg', 'Descripcion de la foto.') } src='../images/divisiones/reparacion-tanques/pead/pead-01.jpg' alt='Diseño de interconexión entre purgas con cañerías de PEAD ' loading='lazy'/>
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/pead/pead-02.jpg', 'Descripcion de la foto.') } src='../images/divisiones/reparacion-tanques/pead/pead-02.jpg' alt='Diseño de interconexión entre purgas con cañerías de PEAD ' loading='lazy' />
                       
                    </div>

                    <div className='gallery__cont--column'>
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/pead/pead-04.jpg', 'Descripcion de la foto.') } src='../images/divisiones/reparacion-tanques/pead/pead-04.jpg' alt='Diseño de interconexión entre purgas con cañerías de PEAD ' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/pead/pead-01.jpg', 'Descripcion de la foto.') } src='../images/divisiones/reparacion-tanques/pead/pead-03.jpg' alt='Diseño de interconexión entre purgas con cañerías de PEAD ' loading='lazy' />
                       
                    </div>

                    <div className='gallery__cont--column'>
                        
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/pead/pead-08.jpg', 'Descripcion de la foto.') } src='../images/divisiones/reparacion-tanques/pead/pead-08.jpg' alt='Diseño de interconexión entre purgas con cañerías de PEAD ' loading='lazy' />
                    </div>

                    <div className='gallery__cont--column'>
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/pead/pead-07.jpg', 'Descripcion de la foto.') } src='../images/divisiones/reparacion-tanques/pead/pead-07.jpg' alt='Diseño de interconexión entre purgas con cañerías de PEAD ' loading='lazy' />
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/pead/pead-06.jpg', 'Descripcion de la foto.') } src='../images/divisiones/reparacion-tanques/pead/pead-06.jpg' alt='Diseño de interconexión entre purgas con cañerías de PEAD ' loading='lazy' /> 
                       
                       
                    </div>

                    <div className='gallery__cont--column'>
                       
                        <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/pead/pead-05.jpg', 'Descripcion de la foto.') } src='../images/divisiones/reparacion-tanques/pead/pead-05.jpg' alt='Diseño de interconexión entre purgas con cañerías de PEAD ' loading='lazy' />
                        
                        
                    </div> 
                
                </div>
                </div>
            </div>

            <div className='container-fluid otros__proyectos'>
                <div className='container'>
                    <h3 className='obras__titles obras__titles--box text-center pb-5 pt-0'>Otros Proyectos</h3>
                    <div className='row d-flex justify-content-center'>
                        <ObrasRealizadasItem 
                            alt = {'Arenado y pintura'}
                            img = {'../images/divisiones/division-banners/divisionBanners-arenado-y-pintura.jpg'}
                            link = {'/reparacion-tanques/arenado-pintura/'}
                            text = {'Arenado y pintura'}

                        />
                        <ObrasRealizadasItem 
                            img={'../images/divisiones/division-banners/divisionBanners-piso-reparacion.jpg'}
                            alt={'Piso: Reparación y Construcción'}
                            link={'/reparacion-tanques/piso-reparacion-y-construccion/'}
                            text={'Piso: Reparación y Construcción'}
                        />
                        <ObrasRealizadasItem 
                            alt = {'Techo: Reparación y Construcción'}
                            img = {'../images/divisiones/division-banners/divisionBanners-techo-reparacion.jpg'}
                            link = {'/reparacion-tanques/techo-reparacion-y-construccion/'}
                            text = {'Techo: Reparación y Construcción'}
                        /> 
                        <ObrasRealizadasItem 
                            alt = {'Still Pipe '}
                            img = {'../images/divisiones/division-banners/divisionBanners-still-pipe.jpg'}
                            link = {'/reparacion-tanques/still-pipe/'}
                            text = {'Still Pipe '}
                        /> 
                        <ObrasRealizadasItem 
                            alt = {'Cañerias y Accesorios'}
                            img = {'../images/divisiones/division-banners/divisionBanners-canerias-y-accesorios.jpg'}
                            link = {'/reparacion-tanques/canerias-y-accesorios/'}
                            text = {'Cañerias y Accesorios'}
                        /> 
                    </div>
                </div>  
            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default Pead