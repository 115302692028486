
import React, {useState, useEffect} from 'react'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import '../Divisiones/DivisionesDetalles.scss'
import DivisionBanner from '../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'
import ObraThumb from '../Commons/ObraThumb/ObraThumb';
import OtrasObras from '../Commons/ObrasRealizadas/OtrasObras';


const EnlacesPuntoAPuntoLargoVanos = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Red Completa de FTTH Rada Tilly, Chubut</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>

              {/* <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer> */}

            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-telecomunicaciones.jpg" 
                    alt="Obras, andamios." 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-obras' >
                    <h3 className='divisionItem__container--header'>OBRAS</h3>
                    <h1 className='divisionItem__container--title'>TELECOMUNICACIONES</h1>
                </div>

            </div>

            <div className='container-fluid obrasItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/obras/'}>
                        <small> 
                        <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                        Volver a Obras
                        </small>
                    </Link>

                    <div className='row'>

                        <div className='col-md-4'>     
                            <img 
                                src="../images/obras/telecomunicaciones/enlace-punto-a-punto-de-largos-vanos.jpg" 
                                alt="Obra de punto a punto en Largo Vanos."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            /> 
                        </div>

                        <div className='col-md-8 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Enlace Punto a Punto de Largos Vanos por línea de 33kv</h1>
                            
                            <p  className='divisionItem__detail--text pt-2' > 
                                Cooperativas Eléctricas de Pedernales, Norberto de la Riestra y Ernestina fueron conectadas por un enlace de FO desde la Localidad de La Rica donde se encuentra el nodo de ARSAT.<br /><br />
                                Esta FO fu instalada sobre la línea de 33kv de la Consignataria Eden SA en vanos de 160 metros.
                                <br />Se instalaron 75 Km de FO Furukawa desarrollada especialmente.
                                <br /><br />
                            </p>

                        </div>
                    </div>
                    
                    <div className='row justify-content-center'>
                        <div className='row obras__box justify-content-center' style={{ marginTop: '40px'}}>
                            <div className='col-6 col-sm-4 col-md-6 col-lg-4 obras__card'>
                                <ObraThumb  
                                    ObraNum  = {'75'}
                                    ObraVal  = {'KM'}
                                    ObraItem = {''}
                                    ObraDet  = {'Despliegue de Cable 48 FO'}
                                />
                            </div>
                            <div className='col-6 col-sm-4 col-md-6 col-lg-4 obras__card'>
                                <ObraThumb  
                                    ObraNum  = {'4'}
                                    ObraVal ={''}
                                    ObraItem = {'LOCALIDADES'}
                                    ObraDet  = {'Enlazadas a la RFFO de ARSAT'}
                                />
                            </div>
                            <div className='col-6 col-sm-4 col-md-6 col-lg-4 obras__card'>
                                <ObraThumb  
                                    ObraNum  = {'10.000'}
                                    ObraVal ={''}
                                    ObraItem = {'Hogares'}
                                    ObraDet  = {''}
                                />
                            </div>
                        </div>
                    </div>
                   

                    <div className='gallery__cont'>

                        <div className='gallery__cont--column25'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/enlace-punto-a-punto-de-largos-vanos/punto-a-punto-largos-vanos-01.jpg', 'Obra de punto a punto en Largo Vanos') } src='../images/obras/telecomunicaciones/enlace-punto-a-punto-de-largos-vanos/punto-a-punto-largos-vanos-01.jpg' alt='Red completa de FTTH en Rada Tilly, Chubut, Argentina.' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/enlace-punto-a-punto-de-largos-vanos/punto-a-punto-largos-vanos-02.jpg', 'Obra de punto a punto en Largo Vanos') } src='../images/obras/telecomunicaciones/enlace-punto-a-punto-de-largos-vanos/punto-a-punto-largos-vanos-02.jpg' alt='Red completa de FTTH en Rada Tilly, Chubut, Argentina.' loading='lazy' /> 
                        </div>

                        <div className='gallery__cont--column25'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/enlace-punto-a-punto-de-largos-vanos/punto-a-punto-largos-vanos-03.jpg', 'Obra de punto a punto en Largo Vanos') } src='../images/obras/telecomunicaciones/enlace-punto-a-punto-de-largos-vanos/punto-a-punto-largos-vanos-03.jpg' alt='Red completa de FTTH en Rada Tilly, Chubut, Argentina.' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/enlace-punto-a-punto-de-largos-vanos/punto-a-punto-largos-vanos-04.jpg', 'Obra de punto a punto en Largo Vanos') } src='../images/obras/telecomunicaciones/enlace-punto-a-punto-de-largos-vanos/punto-a-punto-largos-vanos-04.jpg' alt='Red completa de FTTH en Rada Tilly, Chubut, Argentina.' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column25'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/enlace-punto-a-punto-de-largos-vanos/punto-a-punto-largos-vanos-05.jpg', 'Obra de punto a punto en Largo Vanos') } src='../images/obras/telecomunicaciones/enlace-punto-a-punto-de-largos-vanos/punto-a-punto-largos-vanos-05.jpg' alt='Red completa de FTTH en Rada Tilly, Chubut, Argentina.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/enlace-punto-a-punto-de-largos-vanos/punto-a-punto-largos-vanos-08.jpg', 'Obra de punto a punto en Largo Vanos') } src='../images/obras/telecomunicaciones/enlace-punto-a-punto-de-largos-vanos/punto-a-punto-largos-vanos-08.jpg' alt='Red completa de FTTH en Rada Tilly, Chubut, Argentina.' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column25'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/enlace-punto-a-punto-de-largos-vanos/punto-a-punto-largos-vanos-06.jpg', 'Obra de punto a punto en Largo Vanos') } src='../images/obras/telecomunicaciones/enlace-punto-a-punto-de-largos-vanos/punto-a-punto-largos-vanos-06.jpg' alt='Red completa de FTTH en Rada Tilly, Chubut, Argentina.' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/enlace-punto-a-punto-de-largos-vanos/punto-a-punto-largos-vanos-07.jpg', 'Obra de punto a punto en Largo Vanos') } src='../images/obras/telecomunicaciones/enlace-punto-a-punto-de-largos-vanos/punto-a-punto-largos-vanos-07.jpg' alt='Red completa de FTTH en Rada Tilly, Chubut, Argentina.' loading='lazy' />
                        </div>


                    </div>
                </div>
            </div>
            <div className='otrasObras__container'>

                <h3 className='divisionItem__bajada--tit text-center'>
                   Otras Obras
                </h3>

                <div className='row divisionItem__proyectosDestacados p-0'> 
                    <DivisionBanner 
                        division = {'Enlaces en Rada Tilly'}
                        imgUrl = {'../images/obras/telecomunicaciones/enlaces-en-rada-tilly.jpg'}
                        linkUrl = {'/obras/enlaces-en-rada-tilly/'}
                    /> 
                    <DivisionBanner 
                        division = {'FO para Largos Vanos'}
                        imgUrl = {'../images/obras/telecomunicaciones/fo-para-largos-vanos.jpg'}
                        linkUrl = {'/obras/fo-para-largos-vanos/'}
                    /> 
                    <DivisionBanner 
                        division = {'Coop de Laguna de los Padres'}
                        imgUrl = {'../images/obras/telecomunicaciones/coop-de-laguna-de-los-padres.jpg'}
                        linkUrl = {'/obras/coop-de-laguna-de-los-padres/'}
                    /> 
                    <DivisionBanner 
                        division = {'Enlaces Urbanos punto a punto en Corrientes'}
                        imgUrl = {'../images/obras/telecomunicaciones/enlaces-urbanos-punto-a-punto.jpg'}
                        linkUrl = {'/obras/enlaces-urbanos-punto-a-punto/'}
                    /> 
                </div>

            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default EnlacesPuntoAPuntoLargoVanos