import React , {useEffect, useState} from 'react'
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario';
import Loading                          from '../Commons/Loading/Loading'
import './Contacto.scss'

const Contacto = () => {

    const [loading, setLoading] = useState(true);

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    

    if(loading) {return <Loading />}

    return <section className='container-fluid p-0' style={{ paddingTop: 100 }}> 
                <div>
                    <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2776.9695206799006!2d-67.52041848442828!3d-45.89192267910836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbde454ae1ea35c45%3A0x676824d3ccd8aa2f!2sMarcia%20Riadigos%20524%2C%20U9003%20Comodoro%20Rivadavia%2C%20Chubut!5e0!3m2!1ses-419!2sar!4v1645446330860!5m2!1ses-419!2sar" 
                    width="100%" 
                    height="450" 
                    style={{ border: '0' }}
                    loading="lazy" 
                    title='Construcciones Integrales'/>
                </div>
                <div className='contact__container'>
                    <h1 className='contact__title'>ESCRIBINOS</h1>
                    <p  className='contact__text'>Dejanos tu consulta y nos comunicaremos a la brevedad.</p>
                </div>
                <FooterFormulario/>
            </section>;

};
export default Contacto;
