import React, {useEffect, useState} from 'react'
import '../../Styles/main.scss'
import Loading from '../Commons/Loading/Loading'

import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'

const PoliticaIntegral = () => {
    const [loading, setLoading] = useState(true);

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (
    <section className='container-fluid p-0 display-block'>
        <div className='section__container animated fast fadeIn'>
                   
            <img 
                src="../images/headers/headers-reparacion-de-tanques.jpg" 
                alt="Aislaciones térmicas." 
                loading='lazy'
                className='section__container--img'
            />
            
            <div className='section__container--textos' >
                <h1 className='section__container--title'>Politica Integral</h1>
            </div>
        </div>
        <div className='container section__box'>
            <h3 className='section__tittle'>Política de Calidad, Medio Ambiente, Seguridad y Salud en el Trabajo (CMASS)</h3>
            <p className='section__text'>
            Construcciones Integrales S.R.L. conociendo las responsabilidades frente a sus clientes, y desarrollando sus actividades en el marco de un sistema de gestión integrado, garantizando la calidad de sus productos y servicios, cuidando el medio ambiente, la seguridad y salud de todas las personas que la integran, establece esta política y con el objetivo de alcanzar el más alto nivel competitivo, se compromete a:<br/><br/>
            1 - Cumplir los requisitos legales, propios y del cliente que sean aplicables.<hr />
            2 - Establecer un método de mejora continua asociado al sistema de gestión integrado. <hr />
            3 - Cumplir o exceder las exigencias del mercado y lograr la satisfacción de sus clientes en la prestación  de los 4 - servicios y operaciones desarrolladas por la Empresa.<hr />
            5 - Proteger el medio ambiente, prevenir y minimizar los impactos ambientales adversos y optimizar el uso de los 6 6 - recursos naturales en el desarrollo de las actividades.<hr />
            7 - Proporcionar condiciones de trabajo seguras y saludables en el desarrollo de las actividades.<hr />
            8 - Identificar los peligros y minimizar los riesgos, preservando la Seguridad y Salud de todos sus empleados.<hr />
            9 - Capacitar, concientizar y promover una participación activa, de todo el personal de la empresa en la prevención de riesgos, la preservación del medio ambiente y la ejecución de tareas bajo requisitos de calidad.<hr />
            10 - Desarrollar las actividades bajo procedimientos, controlar cada etapa del proceso, evaluar los resultados, detectar e implementar medidas para alcanzar los estándares de calidad y desarrollar una mejora continua.<hr />
            11 - Asegurar que todos los niveles de la empresa comprendan, implementen y mantengan los más altos niveles de 12 - Calidad, Medio Ambiente, Seguridad y Salud en el trabajo en sus tareas desarrolladas.<hr />
            13 - Difundir esta política a todo el personal propio y contratado, manteniéndola a la vista y disponible a las partes interesadas pertinentes, según corresponda.<hr />
            14 - Revisar periódicamente esta política asegurando que la misma sea apropiada y vigente.<hr />

            </p>
        </div>
        <FooterFormulario />
    </section>
  )
}

export default PoliticaIntegral