import React, { useEffect } from 'react';
import './Loading.scss'

const Loading = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return  <div className='loading'>
             
          </div>;

};

export default Loading;
