import React, {useState, useEffect} from 'react'
import Loading from '../../Commons/Loading/Loading';
import FooterFormulario from '../../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import DivisionBanner from '../../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'

import '../../../Styles/main.scss'
import '../DivisionesDetalles.scss'
import ObrasRealizadasItem from '../../Commons/ObrasRealizadas/ObrasRealizadasItem';

const ArenadoPintura = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Reparacion de tanques: Arenado y Pintura.</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>

              <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer>
            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-reparacion-de-tanques.jpg" 
                    alt="Reparacion de Tanques." 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos' >
                    <h3 className='divisionItem__container--header'>DIVISION</h3>
                    <h1 className='divisionItem__container--title'>REPARACION DE TANQUES</h1>
                </div>

            </div>

            <div className='container-fluid divisionItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/reparacion-tanques/'}>
                    <small> 
                        <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                        Volver a Division</small>
                    </Link>

                    <div className='row'>
                        <div className='col-md-6'> 
                            
                            <img 
                                src="../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura.jpg" 
                                alt="Tanque de 50.000m3. con la obra terminada."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            /> 

                        </div>

                        <div className='col-md-6 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Arenado y pintura</h1>
                            <p  className='divisionItem__detail--text' > 
                                Nuestra empresa cuenta con una experiencia de más de 25 años y un plantel altamente capacitado con certificación bajo INTI. en trabajos de preparación de superficies mediante arenado y aplicación de pinturas industriales. <br /><br />
                                Para los trabajos de arenado utilizamos Granalla metálica, angular y esférica. Y también arenado abrasivo, con arena silícea siendo esta última sometida a análisis para determinar su calidad. <br /><br />
                                Arenamos estructuras metálicas livianas y pesadas, tanques y recipientes, contenedores, chapas lisas y roladas, cañerías y accesorios, entre otros.
                            </p>
                            <h3 className='divisionItem__detail--subtitle' >Control de calidad</h3>
                            <p className='divisionItem__detail--text'>
                                Tenemos un control de calidad que aborda la trazabilidad del producto de principio a fin. Supervisando el material y siguiendo las normas establecidas por cada cliente. Incluimos control ambiental antes de realizar la tarea, luego visual en superficie, micronaje en arenado y granallado, espesores en película húmeda y seca. Certificamos cada trabajo bajo los requerimientos del cliente, siendo este por parte del proveedor o externo.
                            </p>
                            
                        </div>

                    </div>

                    <div className='gallery__cont'>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-1.jpg', 'Descripcion de la foto.') }  
                                    src='../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-1.jpg' alt='Arenado y pintura' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-2.jpg', 'Descripcion de la foto.') }  
                                    src='../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-2.jpg' alt='Arenado y pintura' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-1.jpg', 'Descripcion de la foto.') } 
                                    src='../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-3.jpg' alt='Arenado y pintura' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-4.jpg', 'Descripcion de la foto.') }
                                    src='../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-4.jpg' alt='Arenado y pintura' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-5.jpg', 'Descripcion de la foto.') }
                                    src='../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-5.jpg' alt='Arenado y pintura' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-6.jpg', 'Descripcion de la foto.') }
                                    src='../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-6.jpg' alt='Arenado y pintura' loading='lazy' /> 
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-7.jpg', 'Descripcion de la foto.') }
                                    src='../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-7.jpg' alt='Arenado y pintura' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-8.jpg', 'Descripcion de la foto.') }
                                    src='../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-8.jpg' alt='Arenado y pintura' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-10.jpg', 'Descripcion de la foto.') }
                                    src='../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-10.jpg' alt='Arenado y pintura' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-11.jpg', 'Descripcion de la foto.') }
                                    src='../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-11.jpg' alt='Arenado y pintura' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column'>
                            <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-12.jpg', 'Descripcion de la foto.') }
                                    src='../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-12.jpg' alt='Arenado y pintura' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-9.jpg', 'Descripcion de la foto.') }
                                    src='../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-9.jpg' alt='Arenado y pintura' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-13.jpg', 'Descripcion de la foto.') }
                                    src='../images/divisiones/reparacion-tanques/arenado-y-pintura/arenado-y-pintura-13.jpg' alt='Arenado y pintura' loading='lazy' />
                        </div> 
                    
                    </div>
                </div>
            </div>

            <div className='container-fluid otros__proyectos'>
                <div className='container'>
                    <h3 className='obras__titles obras__titles--box text-center pb-5 pt-0'>Otros Proyectos</h3>
                    <div className='row d-flex justify-content-center'>
                        <ObrasRealizadasItem 
                                img={'../images/divisiones/division-banners/divisionBanners-piso-reparacion.jpg'}
                                alt={'Piso: Reparación y Construcción'}
                                link={'/reparacion-tanques/piso-reparacion-y-construccion/'}
                                text={'Piso: Reparación y Construcción'}
                        />
                        <ObrasRealizadasItem 
                            alt = {'Techo: Reparación y Construcción'}
                            img = {'../images/divisiones/division-banners/divisionBanners-techo-reparacion.jpg'}
                            link = {'/reparacion-tanques/techo-reparacion-y-construccion/'}
                            text = {'Techo: Reparación y Construcción'}
                        /> 
                        <ObrasRealizadasItem 
                            alt = {'Still Pipe '}
                            img = {'../images/divisiones/division-banners/divisionBanners-still-pipe.jpg'}
                            link = {'/reparacion-tanques/still-pipe/'}
                            text = {'Still Pipe '}
                        /> 
                        <ObrasRealizadasItem 
                            alt = {'P.E.A.D'}
                            img = {'../images/divisiones/division-banners/divisionBanners-pead.jpg'}
                            link = {'/reparacion-tanques/pead/'}
                            text = {'P.E.A.D'}
                        />
                        <ObrasRealizadasItem 
                            alt = {'Cañerias y Accesorios'}
                            img = {'../images/divisiones/division-banners/divisionBanners-canerias-y-accesorios.jpg'}
                            link = {'/reparacion-tanques/canerias-y-accesorios/'}
                            text = {'Cañerias y Accesorios'}
                        /> 
                    </div>
                </div>  
            </div>
           
            <FooterFormulario className='mt-5' />
        </section>
    )
}

export default ArenadoPintura