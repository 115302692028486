import React from 'react'
import './Button.scss';

const ButtonDark = ( props ) => {
    return (
        <button className='buttonContainer buttonContainer--dark' type={props.type}>
            {props.toDo}
        </button>
    )
}

export default ButtonDark
