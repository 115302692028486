import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Navbar.scss'

const Navbar = () => {

    const [ click, setClick ] = useState('');
    const [ isActive, setIsActive ] = useState('');
    const [ ariaLabel, setArialaLabel ] = useState('Cerrar menu');

    const showHamburger = () =>{
        if(click !== '') {
            setClick('')
            setArialaLabel('Cerrar Menu')
        }else {
            setClick('naveMenu--visible')
            setArialaLabel('Abrir Menu')
        }
    }

    return (

        <header className='header'>
            <nav className='nav'>

                <Link to={'/'} className='navMenu__logo'>
                    <img src="../images/construcciones-integrales-logo.jpg" className='navMenu__link--logo' alt="Logo de Construcciones Integrales" />
                </Link> 
                
                <button className='navToggle' onClick={ () => showHamburger() } aria-label={ariaLabel}>
                    <i className="fas fa-bars fa-1x"></i>
                </button>
                
                <div className={`navMenu ${click}`}>

                    <div className='navMenu__item d-block d-lg-none'>
                        <Link  to={'/'} className='navMenu__link'  onClick={ () => showHamburger() }  >HOME</Link>
                    </div>

                    <div className='navMenu__item'>
                        <Link  to={'quienes-somos'} onClick={ () => showHamburger()} className='navMenu__link' >QUIENES SOMOS</Link>
                    </div>
                    
                    {/* DIVISIONES PARA MENU MOBILE */}
                    <div className='navMenu__item  d-md-none'>
                        <Link  to={'reparacion-tanques'} onClick={ () => showHamburger()} className='navMenu__link' >REPARACION DE TANQUES</Link>
                    </div>
                    <div className='navMenu__item  d-md-none'>
                        <Link  to={'armado-andamios'} onClick={ () => showHamburger()} className='navMenu__link' >ARMADO DE ANDAMIOS</Link>
                    </div>
                    <div className='navMenu__item  d-md-none'>
                        <Link  to={'aislaciones-termicas'} onClick={ () => showHamburger()} className='navMenu__link' >AISLACION TERMICA</Link>
                    </div>
                    <div className='navMenu__item  d-md-none'>
                        <Link  to={'radiadores'} onClick={ () => showHamburger()} className='navMenu__link' >RADIADORES</Link>
                    </div>
                    <div className='navMenu__item  d-md-none'>
                        <Link  to={'telecomunicaciones'} onClick={ () => showHamburger()} className='navMenu__link' >TELECOMUNICACIONES</Link>
                    </div>
                    <div className='navMenu__item  d-md-none'>
                        <Link  to={'obras-civiles-industriales'} onClick={ () => showHamburger()} className='navMenu__link' >OBRAS CIVILES INDUSTRIALES</Link>
                    </div>


                    <div className='navMenu__item d-none d-md-block'>
                        <div className="dropdown">
                            
                            <div className="navMenu__link">
                                DIVISIONES <i className="fa fa-caret-down"></i>
                            </div>

                            <div className={'dropdown-content'} >  
                                <div className="row">

                                    <div className="column">
                                        <Link to={'reparacion-tanques'}  onClick={ () => showHamburger() } >
                                            <img src="../images/megamenu/submenu-reparacion-tanques.jpg" className='d-block w-100 column__img' alt="Reparacion de Tanques" />
                                            <p className='column__tit'>
                                                Reparacion de Tanques
                                                <i className="fas fa-angle-right" style={{float: 'right'}}></i>
                                            </p>
                                            <div className='column__submenu'>
                                                <Link to={"/reparacion-tanques/arenado-pintura"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Arenado y Pintura</p></div></Link>
                                                <Link to={"/reparacion-tanques/techo-reparacion-y-construccion"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Techo: Reparación y Construcción</p></div></Link>
                                                <Link to={"/reparacion-tanques/piso-reparacion-y-construccion"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Piso: Reparación y Construcción</p></div></Link>
                                                <Link to={"/reparacion-tanques/pead"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>P.E.A.D</p></div></Link>
                                                <Link to={"/reparacion-tanques/still-pipe"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Still Pipe</p></div></Link>
                                                <Link to={"/reparacion-tanques/canerias-y-accesorios"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Cañerias y Accesorios</p></div></Link>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="column">
                                        <Link to={'armado-andamios'}  onClick={ () => showHamburger() } >
                                        <img src="../images/megamenu/submenu-andamios.jpg" className='d-block w-100 column__img' alt="Reparacion de Tanques" />
                                            <p className='column__tit'>
                                                Armado de Andamios
                                                <i className="fas fa-angle-right" style={{float: 'right'}}></i>
                                            </p>
                                        </Link>   
                                    </div>

                                    <div className="column">
                                        <Link to={'aislaciones-termicas'}  onClick={ () => showHamburger() } >
                                            <img src="../images/megamenu/submenu-aislaciones.jpg" className='d-block w-100 column__img' alt="Reparacion de Tanques" />
                                            <p className='column__tit'>
                                                Aislaciones Térmicas
                                                <i className="fas fa-angle-right" style={{float: 'right'}}></i>
                                            </p>
                                            <div className='column__submenu'>
                                                <Link to={"/aislaciones-termicas/cinta-calefactora/"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Cinta calefactora</p></div></Link>
                                                <Link to={"/aislaciones-termicas/recubrimiento-chapa/"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Recubrimiento chapa</p></div></Link>
                                             </div>
                                        </Link>    
                                    </div>

                                    <div className="column">
                                        <Link to={'radiadores'} onClick={ () => showHamburger() }  >
                                        <img src="../images/megamenu/submenu-radiadores.jpg" className='d-block w-100 column__img' alt="Reparacion de Tanques" />
                                            <p className='column__tit'>
                                                Radiadores
                                                <i className="fas fa-angle-right" style={{float: 'right'}}></i>
                                            </p>
                                            {/* <div className='column__submenu'>
                                                <Link to={"/obras/radiador-guascor-fg-24/"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Radiador Guascor FG 240</p></div></Link>
                                                <Link to={"/obras/enfriador-de-aceite/"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Enfriador de Aceite</p></div></Link>
                                            </div> */}
                                        </Link>  
                                    </div>  

                                    <div className="column">
                                        <Link to={'obras-civiles-industriales'} onClick={ () => showHamburger() } >
                                        <img src="../images/megamenu/submenu-obras-civiles.jpg" className='d-block w-100 column__img' alt="Reparacion de Tanques" />
                                            <p className='column__tit column__tit--obras'>
                                                Obras civiles industriales
                                                <i className="fas fa-angle-right" style={{float: 'right'}}></i>
                                            </p>
                                            <div className='column__submenu'>
                                                <Link to={"/obras-civiles-industriales/pisos-tanques"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Pisos de Tanques</p></div></Link>
                                                <Link to={"/obras-civiles-industriales/anillo/"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Anillo</p></div></Link>
                                                <Link to={"/obras-civiles-industriales/bancales-industriales/"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Bancales industriales</p></div></Link>
                                                <Link to={"/obras-civiles-industriales/bancales-para-canerias/"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Bancales para cañerías</p></div></Link>
                                                <Link to={"/obras-civiles-industriales/camara-drenaje-de-purgas/"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Cámara drenaje de purgase</p></div></Link>
                                                <Link to={"/obras-civiles-industriales/veredas/"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Veredas</p></div></Link>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="column">
                                        <Link to={'telecomunicaciones'} onClick={ () => showHamburger() } >
                                        <img src="../images/megamenu/submenu-telecomunicaciones.jpg" className='d-block w-100 column__img' alt="División Telecomunicaciones" />
                                            <p className='column__tit'>
                                                Telecomunicaciones
                                                <i className="fas fa-angle-right" style={{float: 'right'}}></i>
                                            </p>
                                            <div className='column__submenu'>
                                                <Link to={"/telecomunicaciones/fibra-optica/"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Fibra Optica</p></div></Link>
                                                <Link to={"/telecomunicaciones/dts-das/"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>DTS y DAS</p></div></Link>
                                                <Link to={"/telecomunicaciones/fttx/"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>FTTx</p></div></Link>
                                                <Link to={"/telecomunicaciones/networking/"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Networking</p></div></Link>
                                                <Link to={"/telecomunicaciones/video-vigilancia/"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Video Vigilancia</p></div></Link>
                                                <Link to={"/telecomunicaciones/automtizacion-y-domotica/"} ><div className='column__submenu--item'><i className="fas fa-angle-right"></i> <p>Automatización y Domótica</p></div></Link>
                                            </div>
                                        </Link>
                                    </div>

                                </div>
                            </div> 
                        </div> 
                    </div>
                    
                    <div className='navMenu__item'>
                        <Link  to={'obras'} onClick={ () => showHamburger()} className='navMenu__link' >OBRAS</Link>
                    </div>
                   
                    <div className='navMenu__item'>
                        <Link  to={'/trabaja-con-nosotros/'} onClick={ () => showHamburger()} className='navMenu__link' >TRABAJAR EN CI</Link>
                    </div>
                    
                    <div className='navMenu__item'>
                        <Link  to={'contacto'} onClick={ () => showHamburger()} className='navMenu__link' >
                            {/* <i className="fas fa-envelope footer__icon fa-1x"></i> */}
                            CONTACTO
                        </Link>
                    </div>

                </div>
            </nav>
        </header>
        
    )
}

export default Navbar
