
import React, {useState, useEffect} from 'react'
import Loading from '../Commons/Loading/Loading';
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Link } from 'react-router-dom';

import '../Divisiones/DivisionesDetalles.scss'
import DivisionBanner from '../DivisionContainer/DivisionBanner/DivisionBanner';
import { Modal } from 'react-bootstrap'
import ObraThumb from '../Commons/ObraThumb/ObraThumb';
import OtrasObras from '../Commons/ObrasRealizadas/OtrasObras';


const BancoGaliciaPlaza = () => {


    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const [imgUrl, setImgUrl] = useState('');
    const [txtDes, setDesc] = useState('');

    const handleClose = () => {
        setShow(false);
    };
    
    const handleShow = (paramUrl, paramDesc) => {
        
        setImgUrl(paramUrl)
        setDesc(paramDesc)
        setShow(true)
        
        console.log(paramUrl, ', ', paramDesc);
    };

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return (

        <section className='container-fluid p-0 containerBlock' > 


            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Automatización y Domótica.</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <img src={imgUrl} alt="Modal de la imagen amplicada." className='d-block modal__size text-center' loading='lazy' />
              </Modal.Body>

              {/* <Modal.Footer>
                <small >{txtDes}</small>
              </Modal.Footer> */}

            </Modal>

            <div className='divisionItem__container animated fast fadeIn'>
                <img src="../images/headers/headers-telecomunicaciones.jpg" 
                    alt="Obras, andamios." 
                    loading='lazy'
                    className='divisionItem__container--img'    
                />

                <div className='divisionItem__container--textos divisionItem__container--textos-obras' >
                    <h3 className='divisionItem__container--header'>OBRAS</h3>
                    <h1 className='divisionItem__container--title'>BANCO GALICIA PLAZA</h1>
                </div>

            </div>

            <div className='container-fluid obrasItem__detail'>
                <div className='container animated fast fadeIn'>
                    <Link to={'/obras/'}>
                        <small> 
                        <i className="fas fa-angle-double-left" style={{'paddingBottom':'15px', 'paddingRight': '10px'}} ></i> 
                        Volver a Obras
                        </small>
                    </Link>

                    <div className='row'>

                        <div className='col-md-4'>     
                            <img 
                                src="../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-01.jpg" 
                                alt="Vista de plano de obra en Batán."
                                className='d-block w-100 divisionItem__detail--header'
                                loading='lazy'
                            /> 
                        </div>

                        <div className='col-md-8 divisionItem__detail--block'>
                            <h1 className='divisionItem__detail--title' >Automatización y Domótica.</h1>
                            
                            <p  className='divisionItem__detail--text pt-2' > 
                                Hoy es posible utilizar los sistemas de reconocimiento facial, 
                                lectura de patentes, seguimiento de personas y objetos, aperturas 
                                y cierres de puertas, alarmas, barreras, cercos perimetrales, 
                                todo es posible como en las películas más futuristas. 
                                Pero es necesario recorrer un camino para alcanzar el 
                                éxito de cada proyecto. En este camino acompañamos a nuestros 
                                clientes con nuestros expertos en cada área y brindamos toda 
                                nuestra experiencia y conocimiento para lograr el objetivo.
                                <br /><br />
                            </p>

                            <div className='row obras__box justify-content-center' style={{ marginTop: '40px'}}>
                                <div className='col-6 col-sm-4 col-md-6 col-lg-4 obras__card'>
                                    <ObraThumb  
                                        ObraNum  = {'169'}
                                        ObraVal  = {''}
                                        ObraItem = {'NaPS'}
                                        ObraDet  = {''}
                                    />
                                </div>
                                <div className='col-6 col-sm-4 col-md-6 col-lg-4 obras__card'>
                                    <ObraThumb  
                                        ObraNum  = {'65'}
                                        ObraVal ={''}
                                        ObraItem = {'EMPALMES'}
                                        ObraDet  = {''}
                                    />
                                </div>
                                <div className='col-6 col-sm-4 col-md-6 col-lg-4 obras__card'>
                                    <ObraThumb  
                                        ObraNum  = {'35'}
                                        ObraVal ={'KM'}
                                        ObraItem = {'FIBRA OPTICA'}
                                        ObraDet  = {''}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    
                
                    <div className='gallery__cont'>

                        <div className='gallery__cont--column25'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-02.jpg', 'Automatización y domótica en Banco Galicia') } src='../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-02.jpg' alt='Automatización y domótica en Banco Galicia' loading='lazy' /> 
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-01.jpg', 'Mapa de la Red de FTTH instalada en Batán, Bs. As.') } src='../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-01.jpg' alt='Automatización y domótica en Banco Galicia' loading='lazy'/>
                        </div>

                        <div className='gallery__cont--column25'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-03.jpg', 'Automatización y domótica en Banco Galicia') } src='../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-03.jpg' alt='Automatización y domótica en Banco Galicia' loading='lazy'/>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-04.jpg', 'Automatización y domótica en Banco Galicia') } src='../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-04.jpg' alt='Automatización y domótica en Banco Galicia' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column25'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-05.jpg', 'Automatización y domótica en Banco Galicia') } src='../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-05.jpg' alt='Automatización y domótica en Banco Galicia' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-08.jpg', 'Automatización y domótica en Banco Galicia') } src='../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-08.jpg' alt='Automatización y domótica en Banco Galicia' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-09.jpg', 'Automatización y domótica en Banco Galicia') } src='../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-09.jpg' alt='Automatización y domótica en Banco Galicia' loading='lazy' />
                        </div>

                        <div className='gallery__cont--column25'>
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-06.jpg', 'Automatización y domótica en Banco Galicia') } src='../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-06.jpg' alt='Automatización y domótica en Banco Galicia' loading='lazy' />
                            <img    onClick={ () => handleShow('../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-07.jpg', 'Automatización y domótica en Banco Galicia') } src='../images/obras/telecomunicaciones/banco-galicia-plaza/banco-galicia-plaza-07.jpg' alt='Automatización y domótica en Banco Galicia' loading='lazy' />
                        </div>


                    </div>
                </div>
            </div>
            <div>
                <div className='row divisionItem__proyectosDestacados p-0'> 
                    <OtrasObras  itemToShowParam={11} itemsToShowParam={5} />
                </div>
            </div>

            <FooterFormulario className='mt-5' />

        </section>
    )
}

export default BancoGaliciaPlaza