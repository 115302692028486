import React , {useEffect, useState, setShow}   from 'react'
import Loading   from '../Commons/Loading/Loading'

import '../../Styles/main.scss'
import './QuienesSomos.scss'

import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'
import { Modal } from 'react-bootstrap'

import CarouselQuienesSomos from '../Commons/Carousel/CarouselQuienesSomos'

const QuienesSomos = () => {

  const [loading, setLoading] = useState(true);

  useEffect(()=> {
      setTimeout(()=>{
          setLoading(false)
      }, 1000)
  })

  // --------- INICIO DE LOGICA DE CAROUSEL --------- //

  const [show, setShow] = useState(false);
  const [idImg, setIdImg] = useState();

  const handleClose = () => { setShow(false); }
  const handleShow = (idPic) => {
      setShow(true)
      setIdImg(idPic)
      console.log('setIdImg()')

  }

  // --------- FIN DE LOGICA DE CAROUSEL --------- //


  if(loading) {return <Loading />}

  return  <section  className='container-fluid justify-content-center  containerBlock p-0'>

            <Modal
              show={show}
              size='lg'
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Construcciones Integrales.</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <CarouselQuienesSomos index={ idImg }/>
              </Modal.Body>

            
            </Modal>
            
            {/* BLOQUE 1 -  */}
            <div className='quienesSomos__container animated fast fadeIn'>

                <img src="../images/quienes-somos/header-quienes-somos.jpg" 
                    alt="Hombre soldando dentro de un tanque." 
                    loading='lazy'
                    className='quienesSomos__header--img'
                />

                <div className='quienesSomos__container--textos' >
                  <h3 className='quienesSomos__container--header'>INICIOS</h3>
                  <h1 className='quienesSomos__container--title'>COMODORO<br />RIVADAVIA</h1>
                  <p className='quienesSomos__container--text'>
                    Construcciones Integrales S.R.L., inició sus actividades en 1993 en la ciudad de Comodoro Rivadavia, provincia de Chubut. Su actividad principal eran trabajos en P.R.F.V. y la aislación térmica en cañerías y tanques de almacenaje de Petróleo. <br /><br />
                    En 2005, Ante la creciente demanda de las operadoras de tercerizar distintos trabajos se incorporaron servicios como metalurgia, arenado y pintura. <br /><br />
                    Asimismo, la organización fue creciendo en tecnología y equipamiento para continuar migrando hacia los requerimientos de nuestros clientes.
                  </p>
                </div>
                <div className='row quienesSomos__container--images text-center p-0 m-0'>
                    <img onClick={ () => handleShow(0) } src="../images/quienes-somos/inicios-01.jpg" alt="Imagen de los comienzos de Construcciones Integrales." loading='lazy' />
                    <img onClick={ () => handleShow(1) } src="../images/quienes-somos/inicios-02.jpg" alt="Imagen de los comienzos de Construcciones Integrales." loading='lazy' />
                    <img onClick={ () => handleShow(2) } src="../images/quienes-somos/inauguracion-base-nueva.jpg" alt="Imagen de los comienzos de Construcciones Integrales." loading='lazy' />
                    <img onClick={ () => handleShow(3) } src="../images/quienes-somos/inauguracion-base-nueva-placa.jpg" alt="Imagen de los comienzos de Construcciones Integrales." loading='lazy' />
                    <img onClick={ () => handleShow(4) } src="../images/quienes-somos/inauguracion-base-nueva-placa-1.jpg" alt="Imagen de los comienzos de Construcciones Integrales." loading='lazy' />
                    <img onClick={ () => handleShow(5) } src="../images/quienes-somos/inauguracion-base-nueva-equipo.jpg" alt="Imagen de los comienzos de Construcciones Integrales." loading='lazy' />
                </div>

            </div>


            {/* BLOQUE 2, 25 AÑOS  */}
            <div  className='container-fluid containerBlock__25 p-0'>
              
              <div className='container'>
                <div className='row justify-content-center containerBlock__25--bloques'>
                  <div className='col-md-6 containerBlock__gallery'>
                      <div className='containerBlock__column'>
                        <img  onClick={ () => handleShow(6) }  
                              src="../images/quienes-somos/trabajadores-01.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img  onClick={ () => handleShow(7) }
                              src="../images/quienes-somos/trabajadores-02.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img  onClick={ () => handleShow(8) }
                              src="../images/quienes-somos/trabajadores-04.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        
                      </div>

                      <div className='containerBlock__column'>
                        <img  onClick={ () => handleShow(9) } 
                              src="../images/quienes-somos/trabajadores-03.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img  onClick={ () => handleShow(10) } 
                              src="../images/quienes-somos/trabajadores-06.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img  onClick={ () => handleShow(11)} 
                              src="../images/quienes-somos/trabajadores-07.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img  onClick={ () => handleShow(12) }
                              src="../images/quienes-somos/trabajadores-05.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                      </div>

                      <div className='containerBlock__column'>
                        <img  onClick={ () => handleShow(13) } 
                              src="../images/quienes-somos/trabajadores-08.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img  onClick={ () => handleShow(14) } 
                              src="../images/quienes-somos/trabajadores-09.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img  onClick={ () => handleShow(15) } 
                              src="../images/quienes-somos/trabajadores-10.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img  onClick={ () => handleShow(16) } 
                              src="../images/quienes-somos/trabajadores-11.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img  onClick={ () => handleShow(17) } 
                              src="../images/quienes-somos/trabajadores-12.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                      </div>
                  </div>
                  <div className='col-md-6 containerBlock__25--text'>
                    <h2 className='quienesSomos__container--titleBlack'>+30 aÑos en el golfo san jorge</h2>
                    <p className='quienesSomos__container--textBlack'>Al día de hoy CI S.R.L. es una organización que ya cuenta con más de 30 años en el Golfo San Jorge apoyándonos en la calidad, seguridad e integridad; además contamos con un equipo profesional altamente capacitado y certificado que permite que sus servicios sean conformes a las normas de calidad que se relacionan en el ámbito.</p>
                  </div>
                </div>
              </div>
            </div>


            {/*  BLOQUE 2022  */}
            <div className='container-fluid containerBlock__2022 p-0'>
              <div className='container'>
                <div className='row justify-content-center'>
                  <div className='col-md-6 containerBlock__25--text'>
                    <h2 className='quienesSomos__container--titleBlack'>2023</h2>
                    <p className='quienesSomos__container--textBlack'>            
                      Actualmente, la Empresa se dedica a una amplia cartera de servicios y la realización de distintas envergaduras de obras relacionadas a industrias petroleras, termoeléctricas, mineras, entre otras. Entre sus productos y servicios se destacan:<br/><br/>
                      <span className='quienesSomos_hr'>Aislaciones térmicas: frío y calor.</span>
                      <span className='quienesSomos_hr'>Revestimientos P.R.F.V. en pisos de tanques, piletas de decantación.</span>
                      <span className='quienesSomos_hr'>Trabajos en  P.E.A.D. </span>
                      <span className='quienesSomos_hr'>Inyección y spray de espuma de poliuretano.</span>
                      <span className='quienesSomos_hr'>Reparación metalúrgica de tanques de almacenamiento de acuerdo con Normas API 653.</span>
                      <span className='quienesSomos_hr'>Montaje y provisión de Cintas Calefactoras.</span>
                      <span className='quienesSomos_hr'>Venta, fabricación y montaje de caños y accesorios P.R.F.V.</span>
                      <span className='quienesSomos_hr'>Alquiler y armado de Andamios Tubulares. Obras Civiles</span>
                      <span className='quienesSomos_hr'>Arenado y/o granallado, y aplicaciones de pinturas industriales.</span><br/><br/>
                      También contamos con el servicio activo hace más de 30 años en reparación y venta de radiadores.  Somos calificados bajo indicadores de nuestros clientes reconocidos y certificados en el ámbito industrial petrolero.
                    </p>
                  </div>
                  <div className='col-md-6 containerBlock__gallery'>
                      <div className='containerBlock__column'>
                        <img onClick={ () => handleShow(18) } src="../images/quienes-somos/trabajadores-ci-01.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img onClick={ () => handleShow(19) } src="../images/quienes-somos/trabajadores-ci-02.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img onClick={ () => handleShow(20) } src="../images/quienes-somos/trabajadores-ci-04.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img onClick={ () => handleShow(21) } src="../images/quienes-somos/trabajadores-ci-05.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img onClick={ () => handleShow(22) } src="../images/quienes-somos/trabajadores-equipo-03.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img onClick={ () => handleShow(23) } src="../images/quienes-somos/trabajadores-equipo-05.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                      </div>
                      <div className='containerBlock__column'>
                        <img onClick={ () => handleShow(24) } src="../images/quienes-somos/trabajadores-ci-03.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img onClick={ () => handleShow(25) } src="../images/quienes-somos/trabajadores-ci-06.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img onClick={ () => handleShow(26) } src="../images/quienes-somos/trabajadores-ci-07.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img onClick={ () => handleShow(27) } src="../images/quienes-somos/trabajadores-equipo-02.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                      </div>
                      <div className='containerBlock__column'>
                        <img onClick={ () => handleShow(28) } src="../images/quienes-somos/trabajadores-ci-08.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img onClick={ () => handleShow(29) } src="../images/quienes-somos/trabajadores-equipo-01.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                        <img onClick={ () => handleShow(30) } src="../images/quienes-somos/trabajadores-equipo-04.jpg" alt="Somos Construcciones Integrales y su gente." loading='lazy' />
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <FooterFormulario className='mt-5' />
            
          </section>;

};

export default QuienesSomos;
