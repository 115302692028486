import React from 'react'

const ObraThumb = ( props ) => {
  return (
    <div className='obras__cont'>
        <div className='obras__cont-inline'>
            <span className={`obras__cont--num ${props.ObrasShort}`}> { props.ObraNum }</span>
            <span className='obras__cont--small'>{ props.ObraVal }</span>
        </div>
        <p className='obras__cont--item'> { props.ObraItem }</p>
        <p className='obras__cont--det'> {props.ObraDet} </p>
      
    </div>
  )
}

export default ObraThumb