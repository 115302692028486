import React, {useState, useEffect} from 'react';
import Loading from '../Commons/Loading/Loading';
import DivisionThumb from './DivisionThumb/DivisionThumb'
import DivisionContainerInternas from '../DivisionContainer/DivisionContainerInternas'
import FooterFormulario from '../Commons/FooterFormulario/FooterFormulario'

import './Divisiones.scss';

const ObrasCiviles = () => {

    const [loading, setLoading] = useState(true);

    useEffect(()=> {
        setTimeout(()=>{
            setLoading(false)
        }, 1000)
    })

    if(loading) {return <Loading />}

    return    <section className='container-fluid p-0 pb-0 display-block'>
                
                <div className='division__container animated fast fadeIn'>
                   
                    <img 
                        src="../images/headers/headers-obras-civiles.jpg" 
                        alt="Radiadores industriales." 
                        loading='lazy'
                        className='division__container--img'
                    />

                    <div className='division__container--textos' >
                        <h3 className='divisionItem__container--header'>DIVISION</h3>
                        <h1 className='division__container--title'>Obras Civiles Industriales</h1>
                        <p className='division__container--text' style={{textAlign: 'justify'}}>
                        Desarrollamos obras de gran infraestructura dentro de yacimientos, bases operativas, terminales y sitios.<br />
                        Contamos con el personal idoneo para la ejecución de las mismas así como personal profesional, técnico y de calidad.
                        Nuestra experiencia en el rubro nos habilita a generar actividades productivas laborales como fondos de pisos de tanques,
                        anillo perimetrales de tanques, veredas peatonales, ingresos vehiculares, bancales soporte de cañerías de 2” a 30”, 
                        cámaras de purgas de fondo, entre otros. Diseñamos, calculamos, materializamos y ejecutamos según requerimiento del 
                        cliente bajo normas vigentes.
                        </p>
                    </div>

                </div>

                <div className='container container__divisiones'>
                    
                    <div className='text-center division__bajada--down' >
                        <i className="fas fa-angle-down fa-3x"></i>
                    </div>
                                        
                    <div className='row justify-content-center proyecto__containers'>
                        <DivisionThumb 
                            imgUrl ={'./images/divisiones/obras-civiles-industriales/anillo/anillo-00.jpg'}
                            titulo ={'Anillo'}
                            linkUrl = {'/obras-civiles-industriales/anillo/'}
                            altImg={'Anillo'}
                        />  
                        <DivisionThumb 
                            imgUrl ={'./images/divisiones/obras-civiles-industriales/bancales-industriales/bancales-industriales-00.jpg'}
                            titulo ={'Bancales industriales'}
                            linkUrl = {'/obras-civiles-industriales/bancales-industriales/'}
                            altImg={'Bancales Industriales'}
                        />
                        <DivisionThumb 
                            imgUrl ={'./images/divisiones/obras-civiles-industriales/bancales-para-canerias/bancales-para-canierias-00.jpg'}
                            titulo ={'Bancales para cañerías'}
                            linkUrl = {'/obras-civiles-industriales/bancales-para-canerias/'}
                            altImg={'Bancales para cañerías'}
                        />
                        <DivisionThumb 
                            imgUrl ={'./images/divisiones/obras-civiles-industriales/camara-drenaje-de-purgas/camara-drenaje-purgas-00.jpg'}
                            titulo ={'Cámara drenaje de purgas'}
                            linkUrl = {'/obras-civiles-industriales/camara-drenaje-de-purgas/'}
                            altImg={'Cámara drenaje de purgas'}
                        />
                        <DivisionThumb 
                            imgUrl ={'./images/divisiones/obras-civiles-industriales/pisos-de-tanques/obra-piso-01.jpg'}
                            titulo ={'Pisos de Tanques'}
                            linkUrl = {'/obras-civiles-industriales/pisos-tanques'}
                            altImg={'Pisos de Tanques'}
                        />
                        <DivisionThumb 
                            imgUrl ={'./images/divisiones/obras-civiles-industriales/veredas/veredas-00.jpg'}
                            titulo ={'Veredas'}
                            linkUrl = {'/obras-civiles-industriales/veredas/'}
                            altImg={'Veredas'}
                        />
                    </div>

                    <h3 className='division__bajada--tit text-center'>
                        Otras divisiones
                    </h3>
                    <div>
                        <DivisionContainerInternas divSelect={'obras-civiles'} />
                    </div>

                </div>

                <FooterFormulario className='mt-5' />
            </section>

};

export default ObrasCiviles;
